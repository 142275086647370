import { Backdrop, CircularProgress } from "@mui/material";

type loadingBackDropProps = {
  open:boolean,
  handleClose: () => void,
}

export default function LoadingBackDrop({open, handleClose}:loadingBackDropProps) {
  return (
    <Backdrop
      sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )  
}