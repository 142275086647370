import React from 'react';
import { Layout } from "./layout"
import { ReactRouterAppProvider } from '@toolpad/core/react-router';
import { NAVIGATION } from './routes';
import { AuthProvider  } from './Context/AuthContext';


const BRANDING = {
  title: 'OSZERO表盘管理系统',
};

function App() {
  return (
    <ReactRouterAppProvider navigation={NAVIGATION} branding={BRANDING}>
        <AuthProvider>
          <Layout />
        </AuthProvider>
    </ReactRouterAppProvider>
  )
}

export default App;
