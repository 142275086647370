import { Box, Button, Paper, styled, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { ChangeEvent, CSSProperties } from "react";
import { refundType } from "../../pages/WxRefunds";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import { useNavigate } from "react-router";

const TableCellCust = styled(TableCell)(() => ({
  padding: "4px",
  minWidth: "80px",
}));

const actionButtonCSSProps: CSSProperties = {
  minHeight: 0,
  minWidth: 0,
  textWrap: "nowrap",
};

const monoSpace: CSSProperties = {
  fontFamily: "monospace",
  whiteSpace: "nowrap",
  textWrap: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

type RefundTableProps = {
  list: refundType[];
  pagination: PaginationType;
  totalcount: number;
  onPageChange: (e: any, page: number) => void;
  onChangeRowsPerPage: (e: ChangeEvent<HTMLInputElement>) => void;
  onRefresh: () => void;
};

export default function RefundTable(props: RefundTableProps) {
  const { list, pagination, onPageChange, onChangeRowsPerPage, totalcount } = props;

  const navigate = useNavigate()

  function openDetail(inv_no:string) {
    navigate('/wxrefund/'+inv_no)
  }

  return (
    <TableContainer component={Paper} sx={{ p: 1 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCellCust>订单号码</TableCellCust>
            <TableCellCust>交易编号</TableCellCust>
            <TableCellCust align="right">退款价格</TableCellCust>
            <TableCellCust>openid</TableCellCust>
            <TableCellCust>发起时间</TableCellCust>
            <TableCellCust>交易状态</TableCellCust>
            <TableCellCust>处理时间</TableCellCust>
            <TableCellCust>Action</TableCellCust>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, idx) => {
            // const date = new Date(item.createtime)
            return (
              <TableRow key={idx}>
                <TableCellCust sx={{...monoSpace, maxWidth:200, width:200}}>{item.inv_no}</TableCellCust>
                <TableCellCust sx={monoSpace}>
                  {item.transaction_id}
                </TableCellCust>
                <TableCellCust align="right" sx={{ maxWidth: 100 }}>
                  {(item.amount / 100).toFixed(2)}
                </TableCellCust>
                <TableCellCust sx={{ maxWidth: 80 }}>
                  <Box
                    sx={{ ...monoSpace, maxWidth: 80, color: "#0288d1" }}
                    onClick={() => console.log(`${item.openid}`)}
                  >
                    {item.openid}
                  </Box>
                </TableCellCust>
                <TableCellCust sx={{minWidth:160}}>{item.createtime}</TableCellCust>
                <TableCellCust>{item.status}</TableCellCust>
                <TableCellCust sx={{minWidth:160}}>{item.resolvetime}</TableCellCust>
                <TableCellCust>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 1, height: 24, }} >
                    <Button size="small" sx={actionButtonCSSProps} variant="outlined" onClick={()=>openDetail(item.inv_no)}>详情</Button>
                    {/* <Button
                      size="small"
                      sx={actionButtonCSSProps}
                      variant="outlined"
                      disabled={item.inv_status !== "SUCCESS"}
                      onClick={()=>handleRefund(item.inv_no)}
                    >
                      主动退款
                    </Button> */}
                  </Box>
                </TableCellCust>
              </TableRow>
            );
          })}
          {list.length === 0 && (
            <TableRow>
              <TableCellCust colSpan={10} align="center">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    justifyContent: "center",
                    color: "GrayText",
                    p: 3,
                  }}
                >
                  <FolderOffIcon />
                  <Typography variant="inherit">未有数据</Typography>
                </Box>
              </TableCellCust>
            </TableRow>
          )}
        </TableBody>
        <TableFooter></TableFooter>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        labelRowsPerPage="每页显示"
        component="div"
        count={totalcount}
        rowsPerPage={pagination.pageSize}
        page={pagination.currentPage}
        colSpan={8}
        onPageChange={onPageChange}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </TableContainer>
  )
}