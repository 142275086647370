import {
  Box,
  Button,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import { invoiceType } from "../../pages/WxInvoices";
import { ChangeEvent, CSSProperties } from "react";
import { useDialogs, useNotifications } from "@toolpad/core";
import { useAuth } from "../../Context/AuthContext";
import { useNavigate } from "react-router";

type InvoiceTableProps = {
  list: invoiceType[];
  pagination: PaginationType;
  totalcount: number;
  onPageChange: (e: any, page: number) => void;
  onChangeRowsPerPage: (e: ChangeEvent<HTMLInputElement>) => void;
  onRefresh: () => void;
};

const TableCellCust = styled(TableCell)(() => ({
  padding: "4px",
  minWidth: "80px",
}));

const actionButtonCSSProps = {
  minHeight: 0,
  minWidth: 0,
  textWrap: "nowrap",
};

const monoSpace: CSSProperties = {
  fontFamily: "monospace",
  whiteSpace: "nowrap",
  textWrap: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export default function InvoiceTable(props: InvoiceTableProps) {
  const { list, pagination, onPageChange, onChangeRowsPerPage, totalcount, onRefresh } =
    props;
  
  const confirmDialog = useDialogs()
  const { token } = useAuth()

  const notification = useNotifications()
  const navigate = useNavigate()


  const confirmContent = (inv_no:string) => {     
    return (
      <Box sx={{display:'flex', flexDirection:'column', gap:1}}>
        <Typography>确定生成退款单</Typography>
        <Typography>订单编号: {inv_no}</Typography>
      </Box>
    )
  }

  function detailInvoice(inv_no:string) {
    navigate('/invoiceDetail/'+inv_no)
  }

  function newRefundTicket(inv_no:string) {
    try {
      const options = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+token
        },
        body: JSON.stringify({
          inv_no
        })
      };
      ( async () => {
        const res = await fetch('/api/newrefund', options)
        if (res.status === 200) {
          onRefresh()
        }
        if (res.status === 400) {
          const detail = await res.json()
          notification.show(detail.detail.message, {
            severity: 'error',
            autoHideDuration: 3000
          })
        }
      })()
    } catch (err) {
      console.log(err)
    }
  }

  async function handleRefund(inv_no:string) {
    const confirmed = await confirmDialog.confirm(confirmContent(inv_no), {
      title: '生成退款单',
      okText: '确定',
      cancelText: '返回'
    })
    if(confirmed) {
      newRefundTicket(inv_no)
    }
  }

  return (
    <TableContainer component={Paper} sx={{ p: 1 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCellCust>产品号码</TableCellCust>
            <TableCellCust align="right">收款价格</TableCellCust>
            <TableCellCust>产品描述</TableCellCust>
            <TableCellCust>openid</TableCellCust>
            <TableCellCust>订单号码</TableCellCust>
            <TableCellCust>交易编号</TableCellCust>
            <TableCellCust>交易状态</TableCellCust>
            <TableCellCust>购买时间</TableCellCust>
            <TableCellCust>Action</TableCellCust>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, idx) => {
            // const date = new Date(item.createtime)
            return (
              <TableRow key={idx}>
                <TableCellCust sx={monoSpace}>{item.item_uid}</TableCellCust>
                <TableCellCust align="right" sx={{ maxWidth: 100 }}>
                  {(item.amount / 100).toFixed(2)}
                </TableCellCust>
                <TableCellCust sx={{ maxWidth: 120 }}>
                  {item.description}
                </TableCellCust>
                <TableCellCust sx={{ maxWidth: 80 }}>
                  <Box
                    sx={{ ...monoSpace, maxWidth: 80, color: "#0288d1" }}
                    onClick={() => console.log(`${item.openid}`)}
                  >
                    {item.openid}
                  </Box>
                </TableCellCust>
                <TableCellCust sx={monoSpace}>{item.inv_no}</TableCellCust>
                <TableCellCust sx={monoSpace}>
                  {item.transaction_id &&
                    "..." + item.transaction_id.slice(-10)}
                </TableCellCust>
                <TableCellCust>{item.inv_status}</TableCellCust>
                <TableCellCust>{item.createtime}</TableCellCust>
                <TableCellCust>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      height: 24,
                    }}
                  >
                    <Button
                      size="small"
                      sx={actionButtonCSSProps}
                      variant="outlined"
                      onClick={() => detailInvoice(item.inv_no)}
                    >
                      详情
                    </Button>
                    <Button
                      size="small"
                      sx={actionButtonCSSProps}
                      variant="outlined"
                      disabled={item.inv_status !== "SUCCESS"}
                      onClick={()=>handleRefund(item.inv_no)}
                    >
                      主动退款
                    </Button>
                  </Box>
                </TableCellCust>
              </TableRow>
            );
          })}
          {list.length === 0 && (
            <TableRow>
              <TableCellCust colSpan={10} align="center">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    justifyContent: "center",
                    color: "GrayText",
                    p: 3,
                  }}
                >
                  <FolderOffIcon />
                  <Typography variant="inherit">未有数据</Typography>
                </Box>
              </TableCellCust>
            </TableRow>
          )}
        </TableBody>
        <TableFooter></TableFooter>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        labelRowsPerPage="每页显示"
        component="div"
        count={totalcount}
        rowsPerPage={pagination.pageSize}
        page={pagination.currentPage}
        colSpan={8}
        onPageChange={onPageChange}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </TableContainer>
  );
}
