import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import InvoiceTable from "../../Components/InvoiceTable";
import { useAuth } from "../../Context/AuthContext";
import { useNavigate } from "react-router";
import { useNotifications } from "@toolpad/core";

export type invoiceType = {
  id: number;
  openid: string;
  unionid: string;
  inv_no: string;
  item_uid: string;
  description: string;
  amount: number;
  createtime: string;
  transaction_id:string;
  inv_status:string;
  product_uid: string;
  resource: string;
};

export default function Wxinvoice() {
  const [search, setSearch] = useState<string>("");
  const [invlist, setInvlist] = useState<invoiceType[]>([]);
  const [invTotal, setInvTotal] = useState<number>(0);
  const [startsearch, setStartsearch] = useState<boolean>(true);
  const [pagination, setPagination] = useState<PaginationType>({
    currentPage: 0,
    pageSize: 10,
    totalRows: 0,
    disablePagi: false,
  });

  const { token } = useAuth();

  const navigate = useNavigate();
  const notification = useNotifications();

  const getInvoice = useCallback(async () => {
    if (!startsearch) return;
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          currentPage: pagination.currentPage,
          pageSize: pagination.pageSize,
          search: search,
          disablePagi: false,
        }),
      };
      const res = await fetch("/api/invoice", options);
      if (res.status === 200) {
        const { list, total } = await res.json();
        setInvlist(list);
        setInvTotal(total);
      }
      if (res.status === 403) {
        navigate("/login");
      }
      if (res.status === 400) {
        const { detail } = await res.json();
        notification.show(detail.message, {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
      setStartsearch(false)
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startsearch, pagination]);

  function onPageChange(e: any, page: number) {
    setPagination((prev) => ({ ...prev, current: page }));
    setStartsearch(true)
  }

  function onChangeRowsPerPage(e: ChangeEvent<HTMLInputElement>) {
    setPagination((prev) => ({
      ...prev,
      pagesize: parseInt(e.target.value, 10),
      current: 0,
    }));
    setStartsearch(true)
  }

  function onRefresh() {
    setStartsearch(true)
  }

  function handleSearch(e: ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  function clearSearch() {
    setSearch("");
    setStartsearch(true);
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      setStartsearch(true);
    }
  }

  useEffect(() => {
    getInvoice();
    return () => setStartsearch(false);
  }, [getInvoice]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Typography variant="h4" sx={{ pb: 2 }}>
        微信小程序订单
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          pb: 1,
        }}
      >
        <TextField
          label="搜索"
          size="small"
          fullWidth
          value={search}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {search.length > 0 && (
                    <IconButton onClick={() => clearSearch()}>
                      <CancelIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            },
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e);
          }}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            handleKeyDown(e);
          }}
        />
        <Button
          sx={{ minWidth: 150, p: 1, mt: 1, mb: 1 }}
          variant="contained"
          size="small"
          onClick={() => setStartsearch(true)}
        >
          搜索
        </Button>
      </Box>
      <InvoiceTable
        list={invlist}
        pagination={pagination}
        totalcount={invTotal}
        onPageChange={onPageChange}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onRefresh={onRefresh}
      />
    </Box>
  );
}
