import React, { ChangeEvent, useMemo, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
// import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
// import FirstPageIcon from '@mui/icons-material/FirstPage';
// import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
// import LastPageIcon from '@mui/icons-material/LastPage';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import PinOutlinedIcon from '@mui/icons-material/PinOutlined';

export type CodesTableListType = {
  id: number;
  uid: string;
  name: string;
  pic: string;
  price: number;
  usprice: number;
  createtime: string;
  updatetime: string;
  unuse: number;
  disclosed: number;
  verified: number;
  wx_onsale: boolean;
};

type CodesTableProps = {
  tablelist : CodesTableListType[],
  onAddProd : (item:CodesTableListType) => void,
  onDetail : (item:CodesTableListType) => void,
  onEdit : (item:CodesTableListType) => void,
}


export default function CodesTable(props: CodesTableProps) {
  const { tablelist, onAddProd, onDetail, onEdit } = props
  const [ pagesize, setPagesize ] = useState<number>(10)
  const [ current, setCurrent ] = useState<number>(0)

  function handlePageChange(e: unknown,page:number) {
    setCurrent(page)
  }

  function handleChangeRowsPerPage(e: ChangeEvent<HTMLInputElement>) {
    setPagesize(parseInt(e.target.value, 10));
    setCurrent(0)
  }

  const visibleRows = useMemo(
    () => [...tablelist].slice(current * pagesize, current * pagesize + pagesize), 
    [current, pagesize, tablelist]
  )

  return (
    <TableContainer component={Paper} sx={{ p: 1 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell ></TableCell>
            <TableCell ></TableCell>
            <TableCell></TableCell>
            <TableCell ></TableCell>
            <TableCell ></TableCell>
            <TableCell></TableCell>
            <TableCell colSpan={4} align="center" sx={{p:0}}>快速统计</TableCell>
            <TableCell ></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>表盘代码</TableCell>
            <TableCell align="center" sx={{ minWidth:80}}>缩略图</TableCell>
            <TableCell sx={{ minWidth:200}}>名称</TableCell>
            <TableCell align="right">价格</TableCell>
            <TableCell align="right">创建日期</TableCell>
            <TableCell align='center'>销售中</TableCell>
            <TableCell align="center">总</TableCell>
            <TableCell align="center">未</TableCell>
            <TableCell align="center">出</TableCell>
            <TableCell align="center">验</TableCell>
            <TableCell sx={{ minWidth:220 }}>操作</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleRows.length > 0 ? (
            visibleRows.map((item, idx) => {
              // const date = new Date(item.createtime)
              return (
                <TableRow key={idx}>
                  <TableCell sx={{fontFamily:'monospace'}}>{item.uid}</TableCell>
                  <TableCell align="center">
                    { item.pic && <img src={'/upload/media/'+item.pic} alt="pic" width='80px'/>}
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell align="right">{item.price}</TableCell>
                  <TableCell align="right">{item.createtime}</TableCell>
                  <TableCell align='center'>
                    {item.wx_onsale ? <CheckCircleOutlineOutlinedIcon color='primary' /> : <DisabledByDefaultOutlinedIcon color='secondary' />}
                  </TableCell>
                  <TableCell align="center" sx={{color:'burlywood'}}>{item.unuse + item.disclosed + item.verified}</TableCell>
                  <TableCell align="center" sx={{color:'burlywood'}}>{item.unuse}</TableCell>
                  <TableCell align="center" sx={{color:'burlywood'}}>{item.disclosed}</TableCell>
                  <TableCell align="center" sx={{color:'burlywood'}}>{item.verified}</TableCell>
                  <TableCell>
                    <Box  sx={{display:"flex",flexDirection:'row',gap:1, height:24}}>
                      <Button onClick={() => onDetail(item)} size="small" sx={{ minHeight:0, minWidth:0}} variant='outlined'>详情</Button>
                      <Button onClick={() => onAddProd(item)} size="small" sx={{ minHeight:0, minWidth:0}} variant='outlined'>增加密卡</Button>
                      <Button onClick={() => onEdit(item)} size="small" sx={{ minHeight:0, minWidth:0}} variant='outlined'>编辑</Button>
                    </Box>
                  </TableCell>
                </TableRow>
              )  
            })
          ) : (
            <TableRow>
              <TableCell colSpan={11} align="center">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    justifyContent: "center",
                    color: "GrayText",
                    p: 3,
                  }}
                >
                  <FolderOffIcon />
                  <Typography variant="inherit">未有数据</Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
        </TableFooter>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        labelRowsPerPage='每页显示'
        component="div"
        count={tablelist.length}
        rowsPerPage={pagesize}
        page={current}
        colSpan={11}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />
    </TableContainer>
  );
}
