import { Box, Button, FormControl, InputLabel, OutlinedInput, Paper, Typography } from "@mui/material";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "../../Context/AuthContext";

const rowFlex:CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  gap: 2
}

const colFlex:CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2
}

const prettyCss: CSSProperties = {
  fontSize: 12,
  color: "GrayText",
  borderWidth: 1,
  backgroundColor: "InfoBackground",
  width: '50%',
}


export default function GeneralOrderDetailPage() {
  const [ refresh, setRefresh ] = useState<boolean>(true)
  const [ detail, setDetail ] = useState<GeneralOrderType>({
    item_uid: 'abc',
    trans_id: 'abc',
    description: 'abc',
    payment_refid: 'abc',
    payer_id: 'abc',
    state: 'SUCCESS',
    currency_code: 'USD',
    price: 23.55,
    createtime: '2025-02-08T14:00:00',
    payment_gateway: 'PAYPAL',
    product_uid: 'abc'
  } as GeneralOrderType)

  const param = useParams()
  const current_refid = param.payment_refid
  const { token } = useAuth()

  const getDetail = useCallback( async () => {
    if (!refresh) return
    try {
      const options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },  
      }
      const response = await fetch('/api/GeneralOrderDetail/'+current_refid, options)
      if (response.status === 200 ) {
        const data = await response.json()
        setDetail(data)
      }
    } catch (err) {
      console.log(err)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  useEffect( () => {
    getDetail()
    return () => setRefresh(false)
  }, [getDetail])

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Typography variant="h4" sx={{ pb: 2 }}>
        详细订单资料
      </Typography>
      <Paper sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column", gap: 2 }} >
        <Box sx={rowFlex}>
          <FormControl sx={{ width: "50ch" }} size="small">
            <InputLabel htmlFor="email-label">电邮地址</InputLabel>
            <OutlinedInput
              label="电邮地址"
              aria-label="email-label"
              defaultValue={detail.createtime ?? ' '}
              value={detail.email ?? ' '}
              readOnly
              disabled
            />
          </FormControl>
          <Button variant='outlined' size="small" color='warning'
            onClick={async ()=>await navigator.clipboard.writeText(detail.email as string)}
          >复制</Button>
        </Box>
        <FormControl sx={{ width: "50ch" }} size="small">
          <InputLabel htmlFor="createtime">订单时间</InputLabel>
          <OutlinedInput
            label="订单时间"
            aria-label="createtime"
            defaultValue={detail.createtime ?? ' '}
            value={detail.createtime ?? ' '}
            readOnly
            disabled
          />
        </FormControl>
        <FormControl sx={{ width: "50ch" }} size="small">
          <InputLabel htmlFor="payment_gateway">付款方法</InputLabel>
          <OutlinedInput
            label="付款方法"
            aria-label="payment_gateway"
            defaultValue={detail.payment_gateway ?? ''}
            value={detail.payment_gateway ?? ''}
            readOnly
            disabled
          />
        </FormControl>
        <Box sx={{ ...rowFlex }}>
          <FormControl sx={{ width: "50ch" }} size="small">
            <InputLabel htmlFor="payment_refid">{detail.payment_gateway}订单号码</InputLabel>
            <OutlinedInput
              label={detail.payment_gateway+'订单号码'}
              aria-label="payment_gateway"
              defaultValue={detail.payment_refid ?? ''}
              value={detail.payment_refid ?? ''}
              readOnly
              disabled
            />
          </FormControl>
          <FormControl sx={{ width: "50ch" }} size="small">
            <InputLabel htmlFor="trans_id">平台订单号码</InputLabel>
            <OutlinedInput
              label="平台订单号码"
              aria-label="trans_id"
              defaultValue={detail.trans_id ?? ''}
              value={detail.trans_id ?? ''}
              readOnly
              disabled
            />
          </FormControl>
        </Box>
        <Box sx={{ ...rowFlex }}>
          <FormControl sx={{ width: "50ch" }} size="small">
            <InputLabel htmlFor="state">订单状态</InputLabel>
            <OutlinedInput
              label="订单状态"
              aria-label="state"
              defaultValue={detail.state ?? ''}
              value={detail.state ?? ''}
              readOnly
              disabled
            />
          </FormControl>
          <FormControl sx={{ width: "50ch" }} size="small">
            <InputLabel htmlFor="product_uid">密卡号码</InputLabel>
            <OutlinedInput
              label="密卡号码"
              aria-label="product_uid"
              defaultValue={detail.product_uid ?? ' '}
              value={detail.product_uid ?? ' '}
              readOnly
              disabled
            />
          </FormControl>
        </Box>
        <Box sx={{ ...rowFlex }}>
          <FormControl sx={{ width: "50ch" }} size="small">
            <InputLabel htmlFor="item_uid">表盘编码</InputLabel>
            <OutlinedInput
              label="表盘编码"
              aria-label="item_uid"
              defaultValue={detail.item_uid ?? ''}
              value={detail.item_uid ?? ''}
              readOnly
              disabled
            />
          </FormControl>
          <FormControl sx={{ width: "50ch" }} size="small">
            <InputLabel htmlFor="description">交易描述</InputLabel>
            <OutlinedInput
              label="交易描述"
              aria-label="payment_gateway"
              defaultValue={detail.description ?? ''}
              value={detail.description ?? ''}
              readOnly
              disabled
            />
          </FormControl>
        </Box>
        <Box sx={{ ...rowFlex }}>
          <FormControl sx={{ width: "50ch" }} size="small">
            <InputLabel htmlFor="currency_code">交易货币</InputLabel>
            <OutlinedInput
              label="交易货币"
              aria-label="currency_code"
              defaultValue={detail.currency_code ?? ' '}
              value={detail.currency_code ?? ' '}
              readOnly
              disabled
            />
          </FormControl>
          <FormControl sx={{ width: "50ch" }} size="small">
            <InputLabel htmlFor="price">交易金额</InputLabel>
            <OutlinedInput
              label="交易金额"
              aria-label="price"
              defaultValue={detail.price.toFixed(2) ?? parseFloat('0').toFixed(2)}
              value={detail.price ?? parseFloat('0').toFixed(2)}
              readOnly
              disabled
            />
          </FormControl>
        </Box>
        <Box sx={{ ...rowFlex }}>
          <Box sx={{ ...colFlex }}>
            <Typography sx={{ fontSize: 12, textDecorationLine: "underline", ml: 2, mb: 1 }}>
              提交订单报文
            </Typography>
            <div style={prettyCss}>
              <pre>{JSON.stringify(JSON.parse(detail.trans_request ?? '[]'), null, 2)}</pre>
            </div>
          </Box>
          <Box sx={{ ...colFlex }}>
            <Typography sx={{ fontSize: 12, textDecorationLine: "underline", ml: 2, mb: 1 }}>
              订单交易报文
            </Typography>
            <div style={prettyCss}>
              <pre>{JSON.stringify(JSON.parse(detail.checkout_response ?? '[]'), null, 2)}</pre>
            </div>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}