import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from '@mui/icons-material/Cancel';
import InvoiceTable from "../../Components/GeneralInvoiceTable";
import { useAuth } from "../../Context/AuthContext";
import { useNavigate } from "react-router";
import { useNotifications } from "@toolpad/core";

const tags:string[] = [
  'SUCCESS',
  'REFUND',
  'NOPAY',
  'CANCEL'
]

const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL

export default function InvoiceListPage() {
  const [ search, setSearch ] = useState<string>('')
  const [list, setList] = useState<GeneralOrderType[]>([]);
  const [invTotal, setInvTotal] = useState<number>(0);
  const [startsearch, setStartsearch] = useState<boolean>(true);
  const [pagination, setPagination] = useState<PaginationType>({
    currentPage: 0,
    pageSize: 10,
    totalRows: 0,
    disablePagi: false,
  });
  const [isSomething, setIsSomething] = useState<Array<string|null>>(
    new Array(2).fill(null)
  );
  const [selectedTag, setSelectedTag] = useState<string[]>([]);
  const [exeDownload, setExeDownload] = useState<boolean>(false);

  const { token } = useAuth()
  const navigate = useNavigate()
  const notification = useNotifications()

  function clearSearch() {
    setSearch('')
    setStartsearch(true)
  }

  function onSearch() {
    setStartsearch(true)
  }

  function handleSearchInput(e:ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value)
  }

  function handleKeyDown(e:KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      setStartsearch(true);
    }
  }

  const onPageChange = (e: any, page: number) => {
    setPagination(prev => ({ ...prev, currentPage: page }))
    setStartsearch(true)
  }

  const onChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
    setPagination(prev => ({ ...prev, pageSize: parseInt(e.target.value, 10), currentPage: 0 }))
    setStartsearch(true)
  }

  const onRefresh = () => {
    setStartsearch(true)
  }

  const toggleSomething = (gateway:string, index:number) => {
    setPagination((prev) => ({ ...prev, currentPage: 0 }));
    setIsSomething(prev => {
      return prev.map( (p,i) => {
        if ( i === index)
          return p ? null: gateway
        return p
      })
    })
    setStartsearch(true)
  }

  const handleSelector = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedTag(typeof value === "string" ? value.split(",") : value);
    setStartsearch(true)
  }

  const clearTags = () => {
    setSelectedTag([]);
    setStartsearch(true);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const generateExcel = useMemo( async () => {
    if (!exeDownload) return
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          ...pagination,
          search: search,
          gateway: isSomething,
          states: selectedTag, 
          }),
      };
      const response = await fetch("/api/downloadOrderExcel", options);
      if (response.ok) {
        const content = await response.json()
        const fileres = await fetch(`${PUBLIC_URL}download/report/${content.file}`)
        if (fileres.ok) {
          const blob = await fileres.blob()
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.download = content.file
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }      
      }
      if (response.status === 401 || response.status === 403) {
        navigate('/login')
      }
      if (response.status === 400) {
        const detail = await response.text()
        notification.show(detail,{
          severity:'error',
          autoHideDuration: 3000
        })
      }
      setExeDownload(false)
    } catch (err) {
      console.log(err)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exeDownload])


  const getlist = useCallback( async () => {
    if (!startsearch) return
    setStartsearch(false)
    const options = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify({
        ...pagination,
        search: search,
        gateway: isSomething,
        states: selectedTag, 
      })
    }
    const response = await fetch('/api/generalorderlist', options)
    if ( response.status === 200 ) {
      const data = await response.json()
      setList(data.list)
      setInvTotal(data.total)
    }
    if( response.status === 401 || response.status === 403) {
      navigate('/login')
    }
    if( response.status === 400 ) {
      const detail = await response.text()
      notification.show(detail, {
        severity: 'error',
        autoHideDuration: 3000
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startsearch, token])

  useEffect(() => {
    getlist()
  },[getlist])

  const StateFilterComponent = (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pb:2 }}>
      <Typography sx={{ fontSize: 12, textDecorationLine: "underline" }}>
        交易状态筛选器:
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          borderStyle: 'solid',
          borderColor: '#EDEDED',
          p: 1,
        }}
      >
        <FormControl size="small" fullWidth>
          <InputLabel htmlFor="tag">交易状态标签</InputLabel>
          <Select
            labelId="tag"
            id="tag"
            value={selectedTag}
            onChange={handleSelector}
            autoWidth
            multiple
            input={
              <OutlinedInput
                label="交易状态标签"
                endAdornment={
                  <InputAdornment position="end" sx={{ mr: 3 }}>
                    {selectedTag.length > 0 && (
                      <IconButton onClick={() => clearTags()}>
                        <CancelIcon sx={{ fontSize: 16 }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} size="small" />
                ))}
              </Box>
            )}
          >
            {tags &&
              tags.map((t, i) => (
                <MenuItem key={i} value={t}>
                  {t}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText id="tag">
            不选择任何状态=展示所有支付状态
          </FormHelperText>
        </FormControl>

      </Box>
    </Box>
  )

  const GatewayEnablerComponent = (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pb:2 }}>
      <Typography sx={{ fontSize: 12, textDecorationLine: "underline" }}>
        付款方法筛选器:
      </Typography>
      <FormControl size="small" fullWidth>
        <FormGroup
          aria-label="status"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 4,
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "rgba(0,0,0,0.14)",
            borderRadius: "4px",
            px: 1,
          }}
        >
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="ALIPAY"
            onChange={()=> toggleSomething('ALIPAY', 0)}
          />
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="PAYPAL"
            onChange={()=> toggleSomething('PAYPAL', 1)}
          />
        </FormGroup>
        <FormHelperText id="status">
          单选=只显示该状态为True，双选=状态1或状态2为True，全选=所有为True（未选择的状态不参与搜索项）
        </FormHelperText>
      </FormControl>
    </Box>
  );

  const ExcelDownloadComponent = (
    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', gap: 2, pb:2}}>
      <Typography sx={{ fontSize: 12 }}>
        (可下载目前搜索列表) 
      </Typography> 
      <Button size='small' variant='contained' onClick={() => setExeDownload(true)}>下载订单Excel</Button>
    </Box>
  )

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Typography variant="h4" sx={{ pb: 2 }}>
        订单列表
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          pb: 1,
        }}
      >
        <TextField
          label="搜索"
          size="small"
          fullWidth
          value={search}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: ( 
                <InputAdornment position='end'>
                  { search.length > 0 && 
                      <IconButton onClick={()=> clearSearch()} >
                        <CancelIcon sx={{ fontSize:16 }}/> 
                      </IconButton>
                  }
                </InputAdornment>
              )
            },
          }}
          onChange={(e:ChangeEvent<HTMLInputElement>) => { handleSearchInput(e)}}
          onKeyDown={(e:KeyboardEvent<HTMLInputElement>) => { handleKeyDown(e)}}
        />
        <Button
          sx={{ minWidth: 150, p: 1, mt: 1, mb: 1 }}
          variant="contained"
          size="small"
          onClick={onSearch}
        >
          搜索
        </Button>
      </Box>
      { GatewayEnablerComponent }
      { StateFilterComponent }
      { ExcelDownloadComponent }
      <InvoiceTable
        list={list}
        pagination={pagination}
        totalcount={invTotal}
        onPageChange={onPageChange}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onRefresh={onRefresh}
      />
    </Box>
  )
}