import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, styled } from "@mui/material"
import { ChangeEvent, useState } from "react"
import { DialogProps } from "@toolpad/core/useDialogs";

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type ReceiversUploadProps = {
  token: string,
  id: number
}

export function  ReceiversUpload({ open, onClose, payload }: DialogProps<ReceiversUploadProps>) {
  const [uploadState, setUploadState] = useState<boolean>(false)

  const handleUploadExcel = async (e:ChangeEvent<HTMLInputElement>) => {
    setUploadState(true)
    if( e.target.files && e.target.files[0]) {
      const formData = new FormData(); 
      formData.append('file', e.target.files[0])
      formData.append('id', payload.id.toString())
      const options = {
        method: 'POST',
        headers: {
          "Authorization": "Bearer " + payload.token,
        },
        body:formData
      }
      const response = await fetch('/api/uploadReceivers', options)
      if (response.status === 200) {
        onClose()
      }
    }
  }

  return (
    <Dialog fullWidth open={open} onClose={() => onClose()}>
      <DialogTitle>
        上传收件人Excel
      </DialogTitle>
      <DialogContent>
        <Box sx={{p:2, display:'flex', flexDirection:'row' }}>
          <Button variant="contained" color="primary" component="label" role={undefined} tabIndex={-1}
            startIcon={ uploadState ? <CircularProgress size={20}/> :  <CloudUploadOutlinedIcon/>} disabled={uploadState }
            size="large" sx={{ gap: 1, minWidth:1/3 }}>
              选择Excel
            <VisuallyHiddenInput type="file" accept=".xls, .xlsx" onChange={(e)=>handleUploadExcel(e)}/>
          </Button>
        </Box>
       </DialogContent>
     </Dialog>
  )
}