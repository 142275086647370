import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  styled,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, useCallback, useState } from "react";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import { useDropzone } from "react-dropzone";
import { useNotifications } from "@toolpad/core/useNotifications";
import { useAuth } from "../../Context/AuthContext";

const NumberInput = styled(OutlinedInput)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

// const VisuallyHiddenInput = styled("input")({
//   clip: "rect(0 0 0 0)",
//   clipPath: "inset(50%)",
//   height: 1,
//   overflow: "hidden",
//   position: "absolute",
//   bottom: 0,
//   left: 0,
//   whiteSpace: "nowrap",
//   width: 1,
// });

type AddItemProps = {
  open: boolean,
  onClose: () => void,
}

export function AddItem({ open, onClose }: AddItemProps) {
  const [itemName, setItemName] = useState<string>("");
  const [numCodes, setNumCodes] = useState<number>(0);
  const [price, setPrice] = useState<number>(0)
  const [file, setFile] = useState<string>("");

  const { token } = useAuth()

  const notification = useNotifications();

  function handleClose() {
    setFile("");
    setItemName('')
    setNumCodes(0)
    onClose()
  }

  function handleInput(e:ChangeEvent<HTMLInputElement>, actionState:CallableFunction) {
    actionState(e.target.value)
  }

  async function handleCreate() {
    if( numCodes > 100 ) {
      notification.show("保持理智，最多100个号", {
        severity: 'error',
        autoHideDuration: 3000
      })
      return
    };
    if( itemName.length < 5) {
      notification.show("需要提供产品名称，最少几个字哈",{
        severity: 'info',
        autoHideDuration: 3000
      })
      return
    }
    const options = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+token
      },
      body: JSON.stringify({
        name: itemName,
        pic: file,
        code_number: numCodes,
        price: price
      })
    };
    try {
      const res = await fetch('/api/createItem', options)
      if(res.status === 200) {
        notification.show('成功增加产品', {
          severity: 'success',
          autoHideDuration: 3000
        })
      } else {
        const detail = await res.json()
        notification.show(detail.detail.message, {
          severity: 'error',
          autoHideDuration: 3000
        })
      }
    } catch (err) {
      console.log(err)
    }
    onClose()
  }

  const onDrop = useCallback((acceptedFiles: any, fileRejections: any) => {
    // Do something with the files
    if (acceptedFiles.length > 0 && fileRejections.length <= 0) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        setFile(e.target?.result as string);
      };
      fileReader.readAsDataURL(acceptedFiles[0]);
    } else {
      notification.show("选择的图片资源只能是bmp,png或jpg", {
        severity: 'info',
        autoHideDuration: 3000,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png", ".PNG"],
      "image/bmp": [".bmp", ".BMP"],
      "image/jpeg": [".jpg", ".JPG"],
    },
    noClick: file ? true : false,
  });

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle align="center">新增产品</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", pt: 2, gap: 2 }}>
          <TextField
            label="产品名称"
            size="small"
            fullWidth
            value={itemName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {handleInput(e, setItemName)}}
          />
          <FormControl>
            <InputLabel htmlFor="code_num" size="small">
              密卡数量
            </InputLabel>
            <NumberInput
              id="code_num"
              size="small"
              label="验证码数量"
              type="number"
              value={numCodes}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {handleInput(e, setNumCodes)}}
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="code_num" size="small">
              价格 
            </InputLabel>
            <NumberInput
              id="code_num"
              size="small"
              label="验证码数量"
              type="number"
              value={price}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {handleInput(e, setPrice)}}
            />
          </FormControl>
          <Box
            sx={{
              maxHeight: 150,
              height: 150,
              width: 1,
              borderStyle: "dashed",
              borderRadius: 5,
            }}
          >
            <Box
              {...getRootProps()}
              component={"div"}
              sx={{
                display: "flex",
                flexDirection: "column",
                height: 1,
                justifyContent: "center",
                alignItems: "center",
                color: "GrayText",
              }}
            >
              <input {...getInputProps()} />
              {!!!file ? (
                !isDragActive ? (
                  <AddPhotoAlternateOutlinedIcon
                    sx={{ width: 60, height: 60 }}
                  />
                ) : (
                  <ArrowDropDownCircleOutlinedIcon
                    sx={{ width: 60, height: 60 }}
                  />
                )
              ) : (
                <img src={file} alt="preview" height="90%" />
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCreate}>确定增加</Button>
        <Button variant="outlined" onClick={handleClose}>取消</Button>
      </DialogActions>
    </Dialog>
  );
}
