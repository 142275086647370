import { Autocomplete, Box, Button, Checkbox, Chip, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from 'tinymce';
import { ChangeEvent, CSSProperties, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router";
import { useNotifications } from "@toolpad/core";
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import validator from 'validator'

const rowFlexStyle:CSSProperties = {
  display:'flex',
  flexDirection:'row',
  gap:1
}

const colFlexStyle:CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap:1
}

export default function EmailCampaignEditPage() {
  const editorRef = useRef<TinyMCEEditor|null>(null)

  const [watchfaceEnabled, setWatchfaceEnabled] = useState<boolean>(false)
  const [activationCodeEnabled, setActivationCodeEnabled] = useState<boolean>(false)
  const [watchfaceList, setWatchfaceList] = useState<ItemType[]>([])
  const [itemUidString, setItemUidString] = useState<string>('')
  const [useLanguage, setUseLanguage] = useState<string>('zh')
  const [receivers, setReceivers] = useState<string[]>([])
  const [email, setEmail] = useState<string>('')
  const [name, setName] = useState<string>('')
  // const [saveDisable, setSaveDisable] = useState<boolean>(true)

  const { token } = useAuth()
  const navigate = useNavigate()
  const notification = useNotifications()

  const toggleWatchface = () => {
    if (activationCodeEnabled) {
      setWatchfaceEnabled(false)
      setActivationCodeEnabled(false)
    } else {
      setWatchfaceEnabled(prev => !prev)
    }
  }

  const toggleActivationCode = () => {
    if (!activationCodeEnabled) {
      setWatchfaceEnabled(true)
      setActivationCodeEnabled(true)
    } else {
      setActivationCodeEnabled(prev => !prev)
    }
  }

  const controlledTypingItemUid = (e:ChangeEvent<HTMLInputElement>) => {
    setItemUidString(e.target.value)
  }

  const changLanguage = (e:ChangeEvent<HTMLInputElement>) => {
    setUseLanguage(e.target.value)
  }

  const handleEmailInput = (e:ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const clearEmailInput = () => {
    setEmail('')
  }

  const handleNameInput = (e:ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const clearNameInput = () => {
    setName('')
  }

  const addEmail = () => {
    const emailarray:string[] = email.split('\n')
    let validemail:string[] = []
    emailarray.forEach(e => {
      if (validator.isEmail(e.trim())) {
        validemail.push(e.trim())
      }
    });
    setReceivers(prev => [...prev, ...validemail])
    setEmail('')
  }

  const delEmail = (index:number) => {
    setReceivers(prev => [
      ...prev.slice(0, index), ...prev.slice(index+1)
    ])
  }

  const saveCampaign = async () => {
    if ( name.length === 0) {
      notification.show('必须填写活动名称', {
        severity: 'warning',
        autoHideDuration: 3000
      })
      return
    }
    if (! editorRef.current?.getContent()) {
      notification.show('电邮内容不可为空', {
        severity:'warning',
        autoHideDuration: 3000
      })
      return
    }
    if ( watchfaceEnabled ) {
      if (!itemUidString) {
        notification.show('必须选择一个表盘', {
          severity:'warning',
          autoHideDuration: 3000
        })
        return
      }
    }
    let validemail:string[] = []
    if (email.length > 0) {
      const emailarray:string[] = email.split('\n')
      emailarray.forEach(email => {
        if (validator.isEmail(email.trim())) {
          validemail.push(email.trim())
        }
      });
    }
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify({
          content: editorRef.current ? editorRef.current.getContent() : '',
          language: useLanguage, 
          includeWatchface: watchfaceEnabled,
          itemUid: itemUidString,
          includeActivateCode: activationCodeEnabled,
          receivers: [...receivers, ...validemail],
          name,
        })
      }
      const response = await fetch('/api/newEmailCampaign', options)
      if (response.status === 200) {
        const content = await response.text()
        notification.show('成功 '+content, {
          severity: 'success',
          autoHideDuration: 3000
        })
        navigate('/emailCampaign')
      }
      if (response.status === 401 || response.status === 403) {
        navigate('/login')
      }
      if (response.status === 400 ) {
        const detail = await response.text()
        notification.show(detail, {
          severity:'error',
          autoHideDuration: 3000
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getWatchFaces = useCallback(async () => {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
          disablePagi: true
        })
      }
      const response = await fetch('/api/watchlist', options)
      if (response.status === 200) {
        const data = await response.json()
        setWatchfaceList(data)  
      }
      if (response.status === 401 || response.status === 403) {
        navigate('/login')
      }
      if (response.status === 400) {
        const detail = await response.text()
        notification.show(detail, {
          severity: 'error',
          autoHideDuration: 3000
        })
      }
    } catch (err) {
      console.log(err)
    }
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getWatchFaces()
  },[getWatchFaces])

  const StickyButtonsComponent = (
    <Box sx={(theme) => (
        {
          position:'sticky', 
          width:1, 
          ...rowFlexStyle, 
          backgroundColor: theme.palette.mode === 'light' ?'white':'#121212', bottom:0, justifyContent:'flex-end',
          pb:'16px'
        }
      )}
    >
       <Button variant='contained' sx={{mr:2}} onClick={saveCampaign} >保存</Button>
    </Box>
  )

  const BizActionComponent = (
    <Box sx={{width:1, mt:1, ...colFlexStyle}}>
      <Typography sx={{ fontSize: 12, textDecorationLine: "underline" }}>
        启用数据及功能项:
      </Typography>
      <Box sx={{...rowFlexStyle, alignItems:'center'}}>
        <FormControl component="fieldset" size="small" sx={{m:0}}>
            <FormControlLabel
              value="watchCode"
              control={
                <Checkbox onClick={toggleWatchface} checked={watchfaceEnabled}/>
              }
              label="使用表盘数据:"
              labelPlacement='start'
            />
        </FormControl>
        {
          watchfaceEnabled && (
            <Autocomplete
            size="small"
            freeSolo
            options={watchfaceList}
            getOptionLabel={(option) => (option as ItemType).name}
            onChange={(event: any, newValue: ItemType | string | null) => {
              if (newValue && typeof newValue === "object") {
                setItemUidString(newValue.uid)
              } else {
                setItemUidString('')
              }
            }}
            id="item_uid"
            sx={{ minWidth:250}}
            renderInput={(params) => <TextField {...params} label="表盘编码" onChange={controlledTypingItemUid} />}
          />
  
          )
        }
      </Box>
      {
        watchfaceEnabled && (
          <Fragment>
            <Typography sx={{fontSize:'12px'}}>请加入 <span style={{color:'red'}}>{`{{ watchfacename }}`}</span> 在电邮显示表盘名称</Typography>
            <Typography sx={{fontSize:'12px'}}>请加入 <span style={{color:'red'}}>{`{{ watchfacecode }}`}</span> 在电邮显示表盘编码</Typography>
          </Fragment>
        )
      }
      <Box sx={{...rowFlexStyle, alignItems:'center'}}>
        <FormControl component="fieldset" size="small">
            <FormControlLabel
              value="activateCode"
              control={
                <Checkbox onClick={toggleActivationCode} checked={activationCodeEnabled}/>
              }
              label="生成激活码:"
              labelPlacement='start'
            />
        </FormControl>        
        {
          activationCodeEnabled && (
            <Typography sx={{fontSize:'12px'}}>请加入 <span style={{color:'red'}}>{`{{ code }}`}</span> 在电邮显示生成的激活码</Typography>
          )
        }
      </Box>
    </Box>
  ) 

  const LangChooserComponent = (
    <Fragment>
      <Typography sx={{ fontSize: 12, textDecorationLine: "underline" }}>
        选择邮件活动语言:
      </Typography>
      <FormControl size='small'>
        <RadioGroup
          aria-labelledby="language_label"
          defaultValue="zh"
          value={useLanguage}
          name="radio-buttons-group"
          row
          onChange={changLanguage}
        >
          <FormControlLabel value="en" control={<Radio />} label="英文" />
          <FormControlLabel value="zh" control={<Radio />} label="中文" />
        </RadioGroup>
      </FormControl>        
    </Fragment>
  )

  const ReceiversComponent = (
    <Fragment>
      <Typography sx={{ fontSize: 12, textDecorationLine: "underline", pb:2 }}>
        选择邮件活动语言:
      </Typography>
      <Box sx={{...rowFlexStyle, alignItems:'center', pb:1.5}}>
        <TextField fullWidth size='small' multiline rows={5}
          label='收件者电邮'
          value={email}
          onChange={handleEmailInput}
          slotProps={{
            input:{
              endAdornment: (
                <InputAdornment position='end'>
                  { email.length > 0 && 
                      <IconButton onClick={clearEmailInput} >
                        <CancelIcon sx={{ fontSize:16 }}/> 
                      </IconButton>
                  }
                </InputAdornment>
              )   
            }
          }}
        />
        <Chip color='primary' label='增加' icon={<AddCircleOutlineIcon/>} 
          disabled={!(email.length > 0)} 
          clickable={email.length > 0}
          onClick={addEmail}
        />
      </Box>
      {
        receivers.length > 0 && receivers.map((item,i) => (
          <Box key={i} sx={{...rowFlexStyle, alignItems:'center', pb:1.5}}>
            <FormControl size='small' fullWidth>
              <InputLabel variant='outlined' htmlFor='default_input'>收件者电邮</InputLabel>
                <OutlinedInput fullWidth id='default_input' size='small'
                  label='收件者电邮'
                  value={item}
                  onChange={handleEmailInput}
                  endAdornment={
                    <InputAdornment position='end'>
                      { email.length > 0 && 
                          <IconButton onClick={clearEmailInput} >
                            <CancelIcon sx={{ fontSize:16 }}/> 
                          </IconButton>
                      }
                    </InputAdornment>
                  }                  
                />
            </FormControl>
            <Chip color='warning' label='删除' icon={<RemoveCircleOutlineIcon/>} 
              onClick={() => delEmail(i)}
            />
          </Box>  
        ))
      }
    </Fragment>
  )

  const NameComponent = (
    <Box sx={{width:1, mt:1, ...colFlexStyle, pb:2}}>
      <FormControl size='small' sx={{ width: 1/3}}>
        <InputLabel variant='outlined' htmlFor='campaign_name'>活动名称</InputLabel>
          <OutlinedInput fullWidth id='campaign_name' size='small'
            label='活动名称'
            value={name}
            onChange={handleNameInput}
            endAdornment={
              <InputAdornment position='end'>
                { name.length > 0 && 
                    <IconButton onClick={clearNameInput} >
                      <CancelIcon sx={{ fontSize:16 }}/> 
                    </IconButton>
                }
              </InputAdornment>
            }                  
          />
      </FormControl>
    </Box>
  )

  return (
    <Box sx={{position:'relative', height:1}}>
      <Box sx={{ display: "flex", flexDirection: "column", p: 2, minHeight:'calc(100% - 64px)', pb:5}}>
        <Typography variant="h4" sx={{ pb: 2 }}>
          创建/编辑活动电邮
        </Typography>
        { NameComponent }
        <Typography sx={{ fontSize: 12, textDecorationLine: "underline" }}>
          编辑电邮内容:
        </Typography>
        <Editor
          tinymceScriptSrc='/tinymce/tinymce.min.js'
          // apiKey='kc8ncd2h3x9ac26ylw9krnxucpuhzr34nqeldq8pwbdmlbcp'
          onInit={(evt, editor) => editorRef.current = editor}
          init={{
            height: 350,
            menubar: false,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
        { BizActionComponent }
        { LangChooserComponent }
        { ReceiversComponent }
      </Box>
      { StickyButtonsComponent }
    </Box>
  )
}