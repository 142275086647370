import { Box, Button, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { ChangeEvent, CSSProperties, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../../Context/AuthContext";
import { useDialogs, useNotifications } from "@toolpad/core";
import { ConfirmProceed, ReceiversUpload } from "../../Components/Dialogs";

import FolderOffIcon from "@mui/icons-material/FolderOff";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";


const rowFlexStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  gap: 1,
};

const colFlexStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap:1
}

const TableCellCust = styled(TableCell)(() => ({
  padding: '4px'
}))

const AButton = styled(Button)(() => ({
  "&.MuiButton-root": {
    padding: '2px 4px',
    fontSize: '12px',
    minWidth:0,
    minHeight:0,
  },
  textWrap:'nowrap',
}))

const monoSpace: CSSProperties = {
  fontFamily: "monospace",
  whiteSpace: "nowrap",
  textWrap: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const TrueFalseComponent = (state: boolean) => {
  return state ? (
    <CheckCircleOutlineOutlinedIcon color="primary" />
  ) : (
    <DisabledByDefaultOutlinedIcon color="secondary" />
  );
};

export default function EmailCampaignPage() {
  const [list, setList] = useState<EmailCampaignType[]>([])
  const [pagination, setPagination] = useState<PaginationType>({
    pageSize: 10,
    currentPage: 0,
    totalRows: 0,
    disablePagi: false,
  } as PaginationType)
  const [refresh, setRefresh] = useState<boolean>(false)

  const navigate = useNavigate()
  const { token } = useAuth()
  const notification = useNotifications()
  const location = useLocation()
  const dialog = useDialogs()

  const delRow = async (id:number) => {
    await dialog.open(ConfirmProceed, {
      text: '确认删除记录',
      important: '删除后不能恢复',
      confirmText:'删除',
    })
    .then((val) => {
      if(val) {
        (async () => {
          try {
            const options = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
              },
              body: JSON.stringify({
                id
              })
            }
            const response = await fetch('/api/deleteCampaign', options)
            if (response.status === 200) {
              notification.show('成功', {
                'severity': 'success',
                autoHideDuration: 3000
              })
              setRefresh(true)
            }
            if (response.status === 401 || response.status === 403) {
              navigate('/login', { state: { uri: location.pathname }})
            }
          } catch (err) {
            console.log(err)
          }
        })()
      }
    })

  }

  const showEmail = (receivers:string[]) => {
    dialog.alert(receivers.join(', '))
  }

  const onPageChange = (e: any, page: number) => {
    setPagination(prev => ({ ...prev, currentPage: page }))
    setRefresh(true)
  }

  const onChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
    setPagination(prev => ({ ...prev, pageSize: parseInt(e.target.value, 10), currentPage: 0 }))
    setRefresh(true)
  }

  const campaignList = useCallback(async () => {
    setRefresh(false)
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer '+ token
        },
        body: JSON.stringify({
          ...pagination
        })
      }
      const response = await fetch('/api/emailCampaignList', options)
      if (response.status === 200) {
        const data = await response.json()
        setList(data.list)
        setPagination(prev => ({...prev, totalRows:data.total }))
      }
      if (response.status === 401 || response.status === 403) {
        const state = {
          uri: location.pathname
        }
        navigate('/login', { state })
      }
      if (response.status === 400) {
        const detail = await response.text()
        notification.show(detail, {
          severity: 'error',
          autoHideDuration: 3000
        })
      }
    } catch (err) {
      console.log(err)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [refresh])

  const executeAction = async (id:number) => {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer '+token,
        },
        body: JSON.stringify({ id })
      }
      const response = await fetch('/api/executeCampaign', options)
      if (response.status === 202) {
        const content = await response.text()
        notification.show(content, {
          severity: 'success',
          autoHideDuration: 3000
        })
        setRefresh(true)
      }
      if (response.status === 401 || response.status === 403) {
        navigate('/login', { state: { uri: location.pathname }})
      }
    } catch (err) {
      console.log(err)
    }
  }

  const testSendAction = (id:number) => {
    dialog.prompt('电邮地址')
    .then( (val) => {
      (async () => {
        try {
          const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token
            },
            body: JSON.stringify({
              id,
              mailto: val
            })
          }
          const response = await fetch('/api/testCampaignMail', options)
          if (response.status === 202 ) {
            const content = await response.text()
            notification.show(content, {
              severity: 'success',
              autoHideDuration: 3000
            })
          }
          if (response.status === 400 ) {
            const detail = await response.text()
            console.log(detail)
          }
        } catch (err) {
          console.log(err)
        }  
      })()
    })
  }

  const addRecivers = async (id:number) => {
    await dialog.open(ReceiversUpload, {
      token: token as string,
      id: id
    })
    setRefresh(true)
  }

  useEffect(() => {
    campaignList()
  }, [campaignList])

  const AddEventComponent = (
    <Box sx={{ ...rowFlexStyle, justifyContent: "flex-end", width: 1, pb:2 }}>
      <Button variant='contained'
        onClick={() => navigate('/emailCampaignEditor')}
      >
        新增活动
      </Button>
    </Box>
  ) 

  const ListTableComponent = (
    <Paper sx={{...colFlexStyle, p:2 }}>
      <TableContainer>
        <Table sx={{width:1}}>
          <TableHead>
            <TableRow>
              <TableCell>名称</TableCell>
              <TableCell>生成模板</TableCell>
              <TableCell align='center'>含表盘</TableCell>
              <TableCell>表盘码</TableCell>
              <TableCell align='center'>生成密卡</TableCell>
              <TableCell align='center'>语言</TableCell>
              <TableCell align='center'>收件数</TableCell>
              <TableCell>创建时间</TableCell>
              <TableCell>状态</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {
            list.length > 0 && list.map( (row, i) => {
              const _receiverlist = JSON.parse(row.receivers as string)
              return (
                <TableRow key={i}>
                  <TableCellCust>{row.name}</TableCellCust>
                  <TableCellCust sx={{...monoSpace}}>{row.template_name}</TableCellCust>
                  <TableCellCust align='center'>{TrueFalseComponent(row.enableitem)}</TableCellCust>
                  <TableCellCust sx={{...monoSpace}}>{row.item_uid}</TableCellCust>
                  <TableCellCust align='center'>{TrueFalseComponent(row.enableactivation)}</TableCellCust>
                  <TableCellCust align='center'>{row.language}</TableCellCust>
                  <TableCellCust align='center'>
                    <Box sx={{width: 1, color:'var(--mui-palette-primary-main)', ":hover":{ cursor:'pointer'}}}
                      onClick={() => showEmail(_receiverlist)}
                    >
                    {_receiverlist?.length ?? 0}
                    </Box>
                  </TableCellCust>
                  <TableCellCust>{row.createtime}</TableCellCust>
                  <TableCellCust>{row.status}</TableCellCust>
                  <TableCellCust>
                    <Box sx={{...rowFlexStyle, gap:0.5}}>
                      <AButton variant='outlined'
                        onClick={() => testSendAction(row.id)}
                      >
                        测发
                      </AButton>
                      <AButton variant='outlined' color='warning' 
                        disabled={!(row.status === 'READY')}
                        onClick={() => executeAction(row.id)}
                      >
                        执行
                      </AButton>
                      <AButton variant='outlined' disabled={!(row.status === 'PENDING')} onClick={() => addRecivers(row.id)}>上Email</AButton>
                      <AButton variant='outlined' disabled>编辑</AButton>
                      <AButton variant='outlined' color='error' onClick={() => delRow(row.id)}>删除</AButton>
                    </Box>
                  </TableCellCust>
                </TableRow>
              )
            })
          }
          {
            list.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      justifyContent: "center",
                      color: "GrayText",
                      p: 3,
                    }}
                  >
                    <FolderOffIcon />
                    <Typography variant="inherit">未有数据</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )
          }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          labelRowsPerPage="每页显示"
          component="div"
          count={pagination.totalRows ?? 0}
          rowsPerPage={pagination.pageSize}
          page={pagination.currentPage}
          colSpan={10}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  )

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Typography variant="h4" sx={{ pb: 2 }}>
        电邮宣传活动管理
      </Typography>
      { AddEventComponent }
      { ListTableComponent }
    </Box>
  );
}
