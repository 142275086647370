import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  ChangeEvent,
  CSSProperties,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import { useAuth } from "../../Context/AuthContext";
import { useNavigate } from "react-router";
import { useDialogs, useNotifications } from "@toolpad/core";
import { ConfirmProceed } from "../../Components/Dialogs";
import LoadingBackDrop from "../../Components/LoadingBackDrop";

const TableCellCust = styled(TableCell)(() => ({
  padding: "4px",
  minWidth: "40px",
}));

const monoSpace: CSSProperties = {
  fontFamily: "monospace",
  whiteSpace: "nowrap",
  textWrap: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export default function RefundListPage() {
  const [search, setSearch] = useState<string>("");
  const [list, setList] = useState<RefundType[]>([]);
  const [pagination, setPagination] = useState<PaginationType>({
    currentPage: 0,
    pageSize: 10,
    totalRows: 0,
  } as PaginationType);

  const [refresh, setRefresh] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const { token } = useAuth();
  const navigate = useNavigate();
  const notification = useNotifications();
  const dialog = useDialogs();

  const clearSearch = () => {
    setSearch("");
    setRefresh(true);
  };

  const onSearch = () => {
    setRefresh(true);
  };

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setRefresh(true);
    }
  };

  const onPageChange = (e: any, page: number) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
    setRefresh(true);
  };

  const onChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
    setPagination((prev) => ({
      ...prev,
      pageSize: parseInt(e.target.value, 10),
      currentPage: 0,
    }));
    setRefresh(true);
  };

  const confirmRefundAction = async (payment_refid: string) => {
    await dialog
      .open(ConfirmProceed, {
        text: "确认原路退款",
        confirmText: "退款",
        important:
          "退款会请求付款的渠道发起退款申请，发起申请后会立刻删除对应的密卡激活记录",
        highlight: "确认需要执行原路退款",
        confirmCheck: true,
      })
      .then((val) => {
        if (val) {
          setLoading(true);
          confirmRefund(payment_refid);
        }
      });
  };

  const removeRefundRequest = async (payment_refid: string) => {
    await dialog
      .open(ConfirmProceed, {
        text: "撤销退款账单",
        confirmText: "撤销",
        important: "撤销退款单后，可以由账单重新发起",
      })
      .then((val) => {
        if (val) {
          setLoading(true);
          removeRefund(payment_refid);
        }
      });
  };

  const removeRefund = async (payment_refid: string) => {
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          payment_refid,
        }),
      };
      const response = await fetch("/api/rollbackRefund", options);
      if (response.status === 200) {
        setRefresh(true);
        setLoading(false);
        notification.show("成功撤销", {
          severity: "success",
          autoHideDuration: 3000,
        });
      }
      setLoading(false);
      if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }
      if (response.status === 400) {
        const detail = await response.text();
        notification.show(detail, {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const confirmRefund = async (payment_refid: string) => {
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          payment_refid: payment_refid,
        }),
      };
      const response = await fetch("/api/refundorder", options);
      if (response.status === 200) {
        setRefresh(true);
        setLoading(false);
        notification.show("成功执行", {
          severity: "success",
          autoHideDuration: 3000,
        });
      }
      setLoading(false);
      if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }
      if (response.status === 400) {
        const detail = await response.text();
        notification.show(detail, {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getList = useCallback(async () => {
    if (!refresh) return;
    setRefresh(false);
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          ...pagination,
          search: search,
        }),
      };
      const response = await fetch("/api/refundList", options);
      if (response.status === 200) {
        const data = await response.json();
        setList(data.list);
        setPagination((prev) => ({ ...prev, totalRows: data.total }));
      }
      if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }
      if (response.status === 400) {
        const detail = await response.text();
        notification.show(detail, {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
    return () => setRefresh(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    getList();
    return () => setRefresh(false);
  }, [getList]);

  const keySearchComponent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        pb: 1,
      }}
    >
      <TextField
        label="搜索"
        size="small"
        fullWidth
        value={search}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {search.length > 0 && (
                  <IconButton onClick={() => clearSearch()}>
                    <CancelIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleSearchInput(e);
        }}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          handleKeyDown(e);
        }}
      />
      <Button
        sx={{ minWidth: 150, p: 1, mt: 1, mb: 1 }}
        variant="contained"
        size="small"
        onClick={onSearch}
      >
        搜索
      </Button>
    </Box>
  );

  const ListTable = (
    <Paper
      sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column", gap: 2 }}
    >
      <TableContainer component={Paper} sx={{ p: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCellCust>表盘编号</TableCellCust>
              <TableCellCust>产品描述</TableCellCust>
              <TableCellCust>密卡编号</TableCellCust>
              <TableCellCust>订单号码</TableCellCust>
              <TableCellCust>币种</TableCellCust>
              <TableCellCust>金额</TableCellCust>
              <TableCellCust>退款状态</TableCellCust>
              <TableCellCust>创建日期</TableCellCust>
              <TableCellCust>Action</TableCellCust>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCellCust sx={monoSpace}>{item.item_uid}</TableCellCust>
                  <TableCellCust sx={{ maxWidth: 120 }}>
                    {item.name}
                  </TableCellCust>
                  <TableCellCust sx={monoSpace}>
                    {item.product_uid}
                  </TableCellCust>
                  <TableCellCust sx={monoSpace}>
                    {item.payment_refid}
                  </TableCellCust>
                  <TableCellCust>{item.currency_code}</TableCellCust>
                  <TableCellCust>{item.amount.toFixed(2)}</TableCellCust>
                  <TableCellCust>{item.state}</TableCellCust>
                  <TableCellCust>{item.createtime}</TableCellCust>
                  <TableCellCust>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        height: 24,
                      }}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        color="error"
                        disabled={!(item.state === "REQUEST")}
                        onClick={() => confirmRefundAction(item.payment_refid)}
                      >
                        确认退款
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        disabled={!(item.state === "REQUEST")}
                        onClick={() => removeRefundRequest(item.payment_refid)}
                      >
                        取消退款
                      </Button>
                      <Button size="small" variant="outlined" color="info">
                        详情
                      </Button>
                    </Box>
                  </TableCellCust>
                </TableRow>
              );
            })}
            {list.length === 0 && (
              <TableRow>
                <TableCellCust colSpan={9} align="center">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      justifyContent: "center",
                      color: "GrayText",
                      p: 3,
                    }}
                  >
                    <FolderOffIcon />
                    <Typography variant="inherit">未有数据</Typography>
                  </Box>
                </TableCellCust>
              </TableRow>
            )}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          labelRowsPerPage="每页显示"
          component="div"
          count={pagination.totalRows as number}
          rowsPerPage={pagination.pageSize}
          page={pagination.currentPage}
          colSpan={9}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Typography variant="h4" sx={{ pb: 2 }}>
        退款单列表
      </Typography>
      {keySearchComponent}
      {ListTable}
      <LoadingBackDrop open={loading} handleClose={() => setLoading(false)} />
    </Box>
  );
}
