import { Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { KeyboardEvent, ChangeEvent, useState, useCallback, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import RefundTable from "../../Components/RefundTable";
import { useAuth } from "../../Context/AuthContext";
import { useNavigate } from "react-router";

export type refundType = {
  id: number;
  openid: string;
  inv_no: string;
  amount: number;
  createtime: string;
  transaction_id:string;
  status:string;
  resolvetime: string;
}

export default function WxRefunds() {
  const [ search, setSearch ] = useState<string>('')
  const [ startsearch, setStartsearch ] = useState<boolean>(true)
  const [ refundList, setRefundList ] = useState<refundType[]>([])
  const [ total, setTotal ] = useState<number>(0)

  const [pagination, setPagination] = useState<PaginationType>({
    currentPage: 0,
    pageSize: 10,
    totalRows: 0,
    disablePagi: false,
  });

  const { token } = useAuth()
  const navigate = useNavigate()

  const getRefundList = useCallback( async () => {
    if (!startsearch) return;
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          currentPage: pagination.currentPage,
          pageSize: pagination.pageSize,
          search: search,
          disablePagi: false,
        }),
      }
      const res = await fetch('/api/refundlist', options)
      if (res.status === 200) {
        const data = await res.json()
        setRefundList(data.list)
        setTotal(data.total)
      }
      if (res.status === 403) {
        navigate("/login");
      }

    } catch (err) {
      console.log(err)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[startsearch])

  function onPageChange(e: any, page: number) {
    setPagination((prev) => ({ ...prev, current: page }));
    setStartsearch(true)
  }

  function onChangeRowsPerPage(e: ChangeEvent<HTMLInputElement>) {
    setPagination((prev) => ({
      ...prev,
      pagesize: parseInt(e.target.value, 10),
      current: 0,
    }));
    setStartsearch(true)
  }

  function onRefresh() {
    setStartsearch(true)
  }

  function handleSearch(e: ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  function clearSearch() {
    setSearch("");
    setStartsearch(true);
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      setStartsearch(true);
    }
  }

  useEffect(() => {
    getRefundList();
    return () => setStartsearch(false);
  }, [getRefundList]);
  

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Typography variant="h4" sx={{ pb: 2 }}>
        微信退款订单
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          pb: 1,
        }}
      >
        <TextField
          label="搜索"
          size="small"
          fullWidth
          value={search}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {search.length > 0 && (
                    <IconButton onClick={() => clearSearch()}>
                      <CancelIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            },
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e);
          }}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            handleKeyDown(e);
          }}
        />
        <Button
          sx={{ minWidth: 150, p: 1, mt: 1, mb: 1 }}
          variant="contained"
          size="small"
          onClick={() => setStartsearch(true)}
        >
          搜索
        </Button>
      </Box>
      <RefundTable
        list={refundList}
        pagination={pagination}
        totalcount={total}
        onPageChange={onPageChange}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onRefresh={onRefresh}
      />
    </Box>
  )
}