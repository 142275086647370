import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
  Checkbox,
  Divider,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "../../Context/AuthContext";
import { useNotifications } from "@toolpad/core";
import MediaUpload from "../../Components/MediaUpload";
import DropImageComponent from "../../Components/DropImage";

const NumberInput = styled(OutlinedInput)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

const langs:langSetType[] = [
  { name: '英文', code:'en'},
  { name: '日文', code:'jp' },
  { name: '德文', code:'de'},
  { name: '法文', code:'fr'},
  { name: '意大利文', code:'it'},
  { name: '韩文', code:'ko'},
  { name: '西班牙文', code:'es'},
]

type langResObjectType = {
  lang:string,
  name:string,
  tag?:string,
  summary?:string
}

export default function EditItem() {
  const { uid } = useParams();
  const { token } = useAuth();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [item, setItem] = useState<ItemType>({} as ItemType);
  const [profilePic, setProfilePic] = useState<string>("");
  const [custPic, setCustPic] = useState<string>('');
  const [numCodes, setNumCodes] = useState<number>(0);
  const [itemName, setItemName] = useState<string>("");
  const [itemPrice, setItemPrice] = useState<number>(0);
  const [usPrice, setUsPrice] = useState<number>(0);
  const [itemTag, setItemTag] = useState<string>("爆款");
  const [itemSummary, setItemSummary] = useState<string>("");
  const [itemWxonsale, setItemWxonsale] = useState<number>(1);
  const [resLinks, setResLinks] = useState<string[]>([]);
  const [adsLinks, setAdsLink] = useState<string[]>([]);
  const [supportlang, setSupportlang] = useState<string[]>([])
  const [langResObject, setLangResObject] = useState<langResObjectType[]>([])
  const [strikeoutprice, setStrikeoutprice] = useState<number>(0);
  const [strikeoutusprice, setStrikeoutusprice] = useState<number>(0);

  const notification = useNotifications();
  const navigate = useNavigate();

  function handleChange( e: ChangeEvent<HTMLInputElement>, setActions: CallableFunction) {
    setActions(e.target.value);
  }

  function langCheck(e:ChangeEvent<HTMLInputElement>) {
    if(supportlang.indexOf(e.target.value) < 0) {
      setSupportlang(prev => [...prev, e.target.value])
      setLangResObject(prev => [...prev, { lang:e.target.value, name:'', tag:'', summary:''}])
    } else {
      setSupportlang(prev => [ ...prev.slice(0, prev.indexOf(e.target.value)), ...prev.slice(prev.indexOf(e.target.value)+1)])
      setLangResObject(prev => [...prev.slice(0, prev.indexOf(prev.find(i => i.lang === e.target.value) as langResObjectType)), 
        ...prev.slice(prev.indexOf(prev.find(i => i.lang === e.target.value) as langResObjectType)+1)
      ])
    }
  }

  async function handleSubmit() {
    if (numCodes > 100) {
      notification.show("保持理智，最多100个号", {
        severity: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    if (itemName.length < 5) {
      notification.show("需要提供产品名称，最少几个字哈", {
        severity: "info",
        autoHideDuration: 3000,
      });
      return;
    }
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          name: itemName,
          pic: profilePic,
          code_number: numCodes,
          price: itemPrice,
          summary: itemSummary,
          resources: resLinks,
          uid: item?.uid,
          tag: itemTag,
          ads_res: adsLinks,
          wx_onsale: itemWxonsale,
          usprice: usPrice,
          langres: langResObject,
          cuspic: custPic,
          strikeoutprice: strikeoutprice,
          strikeoutusprice: strikeoutusprice,
        }),
      };
      const url = !isEdit ? "/api/createItem" : "/api/updateItem";
      const res = await fetch(url, options);
      if (res.status === 200) {
        notification.show("成功增加产品", {
          severity: "success",
          autoHideDuration: 3000,
        });
        navigate(-1);
      }
      if (res.status === 401 || res.status === 403) {
        const detail = await res.json();
        notification.show(detail.detail.message, {
          severity: "error",
          autoHideDuration: 3000,
        });
        navigate("/login");
      }
      if (res.status >= 400) {
        const detail = await res.json();
        notification.show("Error " + res.status + " " + detail.detail.message, {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getItem() {
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          item_uid: uid,
        }),
      };
      const res = await fetch("/api/item/" + uid, options);
      if (res.status === 200) {
        const data = await res.json();
        setItem(data);
        if (data.resources) {
          setResLinks(JSON.parse(data.resources));
        }
        if (data.ads_res) {
          setAdsLink(JSON.parse(data.ads_res));
        }
        if (data.langs) {
          // eslint-disable-next-line array-callback-return
          data.langs.map( (l:string) => {
            setSupportlang(prev => [...prev, l])
            const jsonobj = data[l]
            let objdetail:langResObjectType = {} as langResObjectType
            if(jsonobj) {
              objdetail = JSON.parse(jsonobj.resobj) as langResObjectType
              objdetail.lang = l
              setLangResObject(prev => [...prev, objdetail])
            } 
          })
        }
        setIsReady(true);
        setItemName(data.name);
        setItemPrice(data.price);
        setProfilePic(data.pic);
        setCustPic(data.cuspic)
        setItemSummary(data.summary);
        setItemTag(data.tag);
        setItemWxonsale(data.wx_onsale);
        setUsPrice(data.usprice);
        setStrikeoutprice(data.strikeoutprice)
        setStrikeoutusprice(data.strikeoutusprice)
      }
      if (res.status === 403) {
        navigate("/login");
      }
      if (res.status === 400) {
        const { detail } = await res.json();
        notification.show(detail.message, {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  function onPicAvailable(link: string, index: number) {
    // setResFiles(prev => [ ...prev, pic ])
    if (index === resLinks.length) {
      setResLinks((prev) => [...prev, link]);
    } else {
      setResLinks((prev) => [
        ...prev.slice(0, index),
        link,
        ...prev.slice(index + 1),
      ]);
    }
  }

  function onAdsAvailable(link: string, index: number) {
    // setResFiles(prev => [ ...prev, pic ])
    if (index === adsLinks.length) {
      setAdsLink((prev) => [...prev, link]);
    } else {
      setAdsLink((prev) => [
        ...prev.slice(0, index),
        link,
        ...prev.slice(index + 1),
      ]);
    }
  }

  function onPicDelete(index: number) {
    setResLinks((prev) => prev.filter((_, i) => i !== index));
  }

  function onAdsDelete(index: number) {
    setAdsLink((prev) => prev.filter((_, i) => i !== index));
  }

  function radioChange(e: ChangeEvent<HTMLInputElement>) {
    setItemWxonsale(parseInt((e.target as HTMLInputElement).value, 10));
  }

  function handleLangChangeName(e:ChangeEvent<HTMLInputElement>, obj:langResObjectType) {
    setLangResObject(prev => [...prev.slice(0,prev.indexOf(obj)), { ...obj, name:e.target.value}, ...prev.slice(prev.indexOf(obj)+1)])  
  }

  function handleLangChangeTag(e:ChangeEvent<HTMLInputElement>, obj:langResObjectType) {
    setLangResObject(prev => [...prev.slice(0,prev.indexOf(obj)), { ...obj, tag:e.target.value}, ...prev.slice(prev.indexOf(obj)+1)])
  }

  function handleLangChangeDesc(e:ChangeEvent<HTMLInputElement>, obj:langResObjectType) {
    setLangResObject(prev => [...prev.slice(0,prev.indexOf(obj)), { ...obj, summary:e.target.value}, ...prev.slice(prev.indexOf(obj)+1)])
  }

  useEffect(() => {
    if (uid) {
      setIsEdit(true);
      setIsReady(false);
      getItem();
    } else {
      setIsEdit(false);
      setIsReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const langform = (lang:langSetType) => {
    const jsonobj = langResObject.find((elm) => (elm.lang === lang.code))
    return (
    <Box sx={{ml:2, display:'flex', flexDirection:'column',gap:1}}>
      <Divider textAlign="left">{lang.name}</Divider>
      <Box sx={{ display:'flex', flexDirection:'row', gap:1}}>
        <FormControl sx={{ width: 1/3 }}>
          <InputLabel htmlFor="textfield_item_n">{lang.name}名称</InputLabel>
          <OutlinedInput
            aria-label="textfield_item_n"
            label={lang.name+'名称'}
            value={jsonobj?.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleLangChangeName(e, jsonobj as langResObjectType)
            }
          />
          <FormHelperText id="textfield_item_n">
            最少5个字
          </FormHelperText>
        </FormControl>
        <FormControl sx={{ width: 1/3 }}>
          <InputLabel htmlFor="textfield_tag">{lang.name}标签</InputLabel>
          <OutlinedInput
            aria-label="textfield_tag"
            label={lang.name+"标签"}
            value={jsonobj?.tag ?? ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleLangChangeTag(e, jsonobj as langResObjectType)
            }
          />
        </FormControl>
        <FormControl sx={{ width: 1/3 }}>
          <InputLabel htmlFor="textfield_item_n">{lang.name}描述</InputLabel>
          <OutlinedInput
            aria-label="textfield_item_n"
            label={lang.name+"描述"}
            multiline={true}
            rows={4}
            value={jsonobj?.summary ?? ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleLangChangeDesc(e, jsonobj as langResObjectType)
            }
          />
        </FormControl>
      </Box>
    </Box>
  )}

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        p: 2,
        gap: 1,
        minHeight: 1,
        width: 1,
      }}
    >
      <Typography variant="h4">
        {!!!isEdit ? "新增" : "编辑"}表盘产品
      </Typography>
      <Paper
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "column",
          p: 1,
          gap: 2,
          pb: "100px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: 3 }}>
          <Box sx={{display:'flex', flexDirection:'column',gap:2}}>
            <Typography sx={{ fontSize: 12, textDecorationLine: "underline", ml: 2, mb: 1 }}>
              表盘主图
            </Typography>
            <DropImageComponent reslink={profilePic} onComplete={(link:string) => setProfilePic(link)}/>
            <Typography sx={{ fontSize: 12, textDecorationLine: "underline", ml: 2, mb: 1 }}>
              自定义图片
            </Typography>
            <DropImageComponent reslink={custPic} onComplete={(link:string) => setCustPic(link)}/>
          </Box>
          <Box
            component="form"
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {!!isReady && (
              <>
                <FormControl>
                  <FormLabel
                    id="wx_onsale_tag"
                    sx={{
                      fontSize: 12,
                      textDecorationLine: "underline",
                      ml: 2,
                      mb: 1,
                    }}
                  >
                    微信出售中
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="wx_onsale_tag"
                    row
                    value={itemWxonsale}
                    sx={{ ml: 2 }}
                    onChange={radioChange}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio size="small" />}
                      label="销售中"
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio size="small" />}
                      label="下架"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl sx={{ width: "40ch" }}>
                  <InputLabel htmlFor="textfield_item_n">名称</InputLabel>
                  <OutlinedInput
                    aria-label="textfield_item_n"
                    label="名称"
                    value={itemName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChange(e, setItemName)
                    }
                  />
                  <FormHelperText id="textfield_item_n">
                    最少5个字
                  </FormHelperText>
                </FormControl>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <FormControl sx={{ width: "40ch" }}>
                    <InputLabel htmlFor="textfield_price">
                      实收人民币价格
                    </InputLabel>
                    <NumberInput
                      aria-label="textfield_price"
                      label="实收人民币价格"
                      type="number"
                      value={itemPrice}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChange(e, setItemPrice)
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ width: "40ch" }}>
                    <InputLabel htmlFor="textfield_price">实收美金价格</InputLabel>
                    <NumberInput
                      aria-label="textfield_price"
                      label="实收美金价格"
                      type="number"
                      value={usPrice}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChange(e, setUsPrice)
                      }
                    />
                  </FormControl>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <FormControl sx={{ width: "40ch" }}>
                    <InputLabel htmlFor="textfield_price">
                      划线人民币价格
                    </InputLabel>
                    <NumberInput
                      aria-label="textfield_price"
                      label="划线人民币价格"
                      type="number"
                      value={strikeoutprice}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChange(e, setStrikeoutprice)
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ width: "40ch" }}>
                    <InputLabel htmlFor="textfield_price">划线美金价格</InputLabel>
                    <NumberInput
                      aria-label="textfield_price"
                      label="划线美金价格"
                      type="number"
                      value={strikeoutusprice}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChange(e, setStrikeoutusprice)
                      }
                    />
                  </FormControl>
                </Box>
                <FormControl sx={{ width: "40ch" }}>
                  <InputLabel htmlFor="textfield_tag">标签</InputLabel>
                  <OutlinedInput
                    aria-label="textfield_tag"
                    label="标签"
                    value={itemTag}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChange(e, setItemTag)
                    }
                  />
                  <FormHelperText id="textfield_tag">最多4个字</FormHelperText>
                </FormControl>
                {!!isEdit ? (
                  <TextField
                    variant="standard"
                    label="表盘代码"
                    defaultValue={item.uid}
                    disabled
                  />
                ) : (
                  <FormControl sx={{ width: "40ch" }}>
                    <InputLabel htmlFor="textfield_num">
                      新增密卡数量
                    </InputLabel>
                    <NumberInput
                      aria-label="textfield_num"
                      label="新增密卡数量"
                      type="number"
                      value={numCodes}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChange(e, setNumCodes)
                      }
                    />
                    <FormHelperText id="textfield_num">
                      一次最多100个
                    </FormHelperText>
                  </FormControl>
                )}
              </>
            )}
            <Box sx={{ display: "flex", width: 1 }}>
              <TextField
                variant="outlined"
                label="描述"
                multiline
                rows={4}
                sx={{ width: 1 }}
                value={itemSummary}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, setItemSummary)
                }
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: 1 }}>
              <Typography
                sx={{
                  fontSize: 12,
                  textDecorationLine: "underline",
                  ml: 2,
                  mb: 1,
                }}
              >
                轮播资源
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "revert",
                  flexWrap: "wrap",
                  width: 1,
                  gap: 2,
                }}
              >
                {adsLinks.map((rfile, idx) => {
                  return (
                    <MediaUpload
                      key={idx}
                      onPicAvailable={onAdsAvailable}
                      onPicDelete={onAdsDelete}
                      index={idx}
                      linkPic={rfile}
                    />
                  );
                })}
                <MediaUpload
                  onPicAvailable={onAdsAvailable}
                  onPicDelete={onAdsDelete}
                  index={adsLinks.length}
                  linkPic={""}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: 1 }}>
              <Typography
                sx={{
                  fontSize: 12,
                  textDecorationLine: "underline",
                  ml: 2,
                  mb: 1,
                }}
              >
                资源
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "revert",
                  flexWrap: "wrap",
                  width: 1,
                  gap: 2,
                }}
              >
                {resLinks.map((rfile, idx) => {
                  return (
                    <MediaUpload
                      key={idx}
                      onPicAvailable={onPicAvailable}
                      onPicDelete={onPicDelete}
                      index={idx}
                      linkPic={rfile}
                    />
                  );
                })}
                <MediaUpload
                  onPicAvailable={onPicAvailable}
                  onPicDelete={onPicDelete}
                  index={resLinks.length}
                  linkPic={""}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Typography variant="h6">语言选项</Typography>
        <Typography
          sx={{
            fontSize: 12,
            textDecorationLine: "underline",
            ml: 2,
            mb: 1,
          }}
        >
            增加语言支持
        </Typography>
        <FormControl>
          <FormGroup
            sx={{ display: "flex", flexDirection: "row", gap: 2, ml: 2 }}
          >
            { langs.map( (item,idx) => (
              <FormControlLabel key={idx} value={item.code} control={
                <Checkbox size="small" checked={supportlang.indexOf(item.code)>=0} onChange={langCheck}/>
                } label={item.name}
              />
            ))}
          </FormGroup>
        </FormControl>
        {
          supportlang.map((item,idx) => (
            <Box key={idx}>
              {langform(langs.find((elm) => (elm.code === item)) as langSetType)}
            </Box>
          ))
        }
      </Paper>
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          width: "calc(100% - 320px - 32px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            justifyContent: "end",
          }}
        >
          <Button variant="contained" onClick={handleSubmit}>
            确定{!!!isEdit ? "增加" : "修改"}
          </Button>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            取消
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return <>{isReady ? content : null}</>;
}
