import { Box, Button, IconButton, InputAdornment, Paper, styled, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, CSSProperties, KeyboardEvent, useMemo, useState } from "react";
import LoadingBackDrop from "../../Components/LoadingBackDrop";
import { useAuth } from "../../Context/AuthContext";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import { useDialogs, useNotifications } from "@toolpad/core";
import { ConfirmProceed } from "../../Components/Dialogs/ConfirmProceed";
import { useNavigate } from "react-router";

const TableCellCust = styled(TableCell)(() => ({
  padding: "4px",
  minWidth: "80px",
}));

const monoSpace: CSSProperties = {
  fontFamily: "monospace",
  whiteSpace: "nowrap",
  textWrap: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export default function TrailListPage() {
  const [ searchString, setSearchString ] = useState<string>('')
  const [ pagination, setPagination ] = useState<PaginationType>({
    currentPage: 0,
    pageSize: 10,
    totalRows: 0
  } as PaginationType)
  const [ list, setList ] = useState<OnTrailType[]>([])

  const [ refresh, setRefresh ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ claimer, setClaimer ] = useState<string|null>(null)
  
  const { token } = useAuth()
  const dialog = useDialogs()
  const notification = useNotifications()
  const navigate = useNavigate()

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setPagination((prev) => ({ ...prev, currentPage: 0 }));
      setRefresh(true);
    }
  };

  const onSearch = () => {
    setRefresh(true)
  }

  const clearSearch = () => {
    setSearchString('')
    setRefresh(true)
  }

  const onPageChange = (e: any, page: number) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
    setRefresh(true);
  };

  const onChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
    setPagination((prev) => ({
      ...prev,
      pageSize: parseInt(e.target.value, 10),
      currentPage: 0,
    }));
    setRefresh(true);
  };

  const handleDelClick = async (c:string) => {
    await dialog.open(ConfirmProceed, {
      text: '删除设备的试用记录',
      confirmText: '删除',
      important: '删除记录后，该设备可以重新得到试用机会'
    }).then((val) => {
      if (val) {
        setLoading(true)
        setClaimer(c)
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const delTrailRecord = useMemo( async () => {
    if ( !claimer ) {
      setLoading(false)
      return
    }
    try {
      const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer '+token
        }
      }
      const response = await fetch('/api/trailDel/'+claimer, options)
      if (response.status === 200) {
        notification.show('成功删除记录', {
          severity: 'success',
          autoHideDuration: 3000
        })
        setClaimer(null)
        setRefresh(true)
      }
      setLoading(false)
      if (response.status === 401 || response.status === 403) {
        navigate('/login')
      }
      if (response.status === 400) {
        const detail = await response.text()
        notification.show(detail, {
          severity:'error',
          autoHideDuration: 3000
        })
      }
    } catch (err) {
      console.log(err)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimer])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getTrailList = useMemo( async () => {
    try {
      const options = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          ...pagination,
          disablePagi: false,
          search: searchString
        })
      }
      const response = await fetch('/api/trailList', options)
      if ( response.status === 200 ) {
        const data = await response.json()
        setList(data.list)
        setPagination(prev => ({ ...prev, totalRows:data.total}))
      }
      setRefresh(false)
      if (response.status === 401 || response.status === 403) {
        navigate('/login')
      }
      if (response.status === 400) {
        const detail = await response.text()
        notification.show(detail, {
          severity:'error',
          autoHideDuration: 3000
        })
      }
    } catch (err) {
      console.log(err)      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])


  const keySearchComponnet = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        mb: 1,
      }}
    >
      <TextField
        label="搜索"
        size="small"
        fullWidth
        value={searchString}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchString.length > 0 && (
                  <IconButton onClick={() => clearSearch()}>
                    <CancelIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleSearchInput(e);
        }}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          handleKeyDown(e);
        }}
      />
      <Button
        sx={{ minWidth: 150, p: 1, mt: 1, mb: 1 }}
        variant="contained"
        size="small"
        onClick={onSearch}
      >
        搜索
      </Button>
    </Box>
  );

  const ListTable = (
    <Paper
      sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column", gap: 2 }}
    >
      <TableContainer component={Paper} sx={{ p: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCellCust>表盘编号</TableCellCust>
              <TableCellCust>产品描述</TableCellCust>
              <TableCellCust>试用设备号</TableCellCust>
              <TableCellCust>创建日期</TableCellCust>
              <TableCellCust>剩余时间</TableCellCust>
              <TableCellCust>Action</TableCellCust>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item, idx) => {
              let date = new Date(item.createtime)
              date.setMinutes(date.getMinutes() + item.allow_mins)
              const now = new Date()
              let reminding = date.getTime() - now.getTime()
              return (
                <TableRow key={idx}>
                  <TableCellCust sx={monoSpace}>{item.item_uid}</TableCellCust>
                  <TableCellCust sx={{ maxWidth: 120 }}>{item.name}</TableCellCust>
                  <TableCellCust sx={monoSpace}>{item.claim_by}</TableCellCust>
                  <TableCellCust>{item.createtime}</TableCellCust>
                  <TableCellCust>{reminding > 0 ? Math.floor(reminding/(1000*60)): 0}</TableCellCust>
                  <TableCellCust>
                    <Box sx={{display:"flex",flexDirection:'row',gap:1, height:24}}>
                      <Button size="small" sx={{ minHeight:0, minWidth:0}} variant='outlined'
                        color='error'
                        onClick={() => handleDelClick(item.claim_by)}
                      >
                        删除
                      </Button>                    
                    </Box>
                  </TableCellCust>
                </TableRow>
              );
            })}
            {list.length === 0 && (
              <TableRow>
                <TableCellCust colSpan={6} align="center">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      justifyContent: "center",
                      color: "GrayText",
                      p: 3,
                    }}
                  >
                    <FolderOffIcon />
                    <Typography variant="inherit">未有数据</Typography>
                  </Box>
                </TableCellCust>
              </TableRow>
            )}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          labelRowsPerPage="每页显示"
          component="div"
          count={pagination.totalRows as number}
          rowsPerPage={pagination.pageSize}
          page={pagination.currentPage}
          colSpan={6}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
    
  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Typography variant="h4" sx={{ pb: 2 }}>
        试用列表
      </Typography>
      { keySearchComponnet }
      { ListTable }
      <LoadingBackDrop open={loading} handleClose={() => setLoading(false)} />
    </Box>
  )
}