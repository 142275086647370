import { createBrowserRouter } from "react-router";
import Dashboard from "./pages/Dashboard";
import Garminlist from './pages/Garminlist'
import type { Navigation } from '@toolpad/core/AppProvider';
import Logout from "./pages/Logout";
import SecretCodes from "./pages/Secretcodes";
import Login from "./pages/Login";
import App from "./App";
import EditItem from "./pages/EditItem";
import Gmrecords from "./pages/Gmresults";
import WxInvoices from "./pages/WxInvoices";
import WxRefunds from "./pages/WxRefunds";
import RefundDetail from "./pages/RefundDetail";
import InvoiceDetail from "./pages/InvoiceDetail";
import InvoiceListPage from "./pages/InvoiceList";
import GeneralOrderDetailPage from "./pages/GeneralOrderDetail";
import ManageProductPage from "./pages/ManageProduct";
import TrailListPage from "./pages/TrailListPage";
import RefundListPage from "./pages/RefundList";
import EmailCampaignPage from "./pages/EmailCampaign";
import EmailCampaignEditPage from "./pages/EmailCampaign/Editor";

import KeyIcon from '@mui/icons-material/Key';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import WatchIcon from '@mui/icons-material/Watch';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import EmailIcon from '@mui/icons-material/Email';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import PlusOneCampaignPage from "./pages/PlusOneCampaign";
import PlusOneEditorPage from "./pages/PlusOneCampaign/Editor";

export const routes = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    element: <App />,
    children: [
      {
        path: '',
        Component: Dashboard
      },
      {
        path: '/watches/garminlist',
        Component: Garminlist
      },
      {
        path: '/logout',
        Component: Logout,
      },
      {
        path: '/secret_codes/:uid',
        Component: SecretCodes
      },
      {
        path: '/edititem',
        Component: EditItem
      },
      {
        path: '/edititem/:uid',
        Component: EditItem
      },
      {
        path: '/watches/gmrecords',
        Component: Gmrecords
      },
      {
        path: '/invoices/wxinvoice',
        Component: WxInvoices
      },
      {
        path: '/invoices/wxrefund',
        Component: WxRefunds
      },
      {
        path: '/wxrefund/:trade_no',
        Component: RefundDetail
      },
      {
        path: '/invoiceDetail/:trade_no',
        Component: InvoiceDetail
      },
      {
        path: '/invoices/list',
        Component: InvoiceListPage
      },
      {
        path: '/orderdetail/:payment_refid',
        Component: GeneralOrderDetailPage 
      },
      {
        path: '/ManageProduct',
        Component: ManageProductPage
      },
      {
        path: '/trail/list',
        Component: TrailListPage
      },
      {
        path: '/refund/list',
        Component: RefundListPage
      },
      {
        path: 'emailCampaign',
        Component: EmailCampaignPage 
      },
      {
        path: 'emailCampaignEditor',
        Component: EmailCampaignEditPage
      },
      {
        path: 'plusOneCampaign',
        Component: PlusOneCampaignPage
      },
      {
        path: 'plusOneEditor',
        Component: PlusOneEditorPage
      }
    ]
  }
])

export const NAVIGATION: Navigation = [
  {
    title: '仪表板',
    icon: <DashboardIcon />,
  },
  {
    kind: 'header',
    title: '主要业务',
  },
  {
    segment: 'watches/garminlist',
    title: '表盘管理',
    icon: <WatchIcon />
  },
  {
    segment: 'watches/gmrecords',
    title: '核销记录',
    icon: <FactCheckIcon />
  },
  {
    segment: 'trail/list',
    title: '试用记录',
    icon: <TimerOutlinedIcon />
  },
  {
    segment: 'ManageProduct',
    title: '密卡管理',
    icon: <KeyIcon /> 
  },
  {
    segment: 'invoices/list',
    title: '订单管理',
    icon: <ReceiptOutlinedIcon />,
  },
  {
    segment: 'refund/list',
    title: '退款管理',
    icon: <MoneyOffIcon />
  },
  {
    segment: 'emailCampaign',
    title: '电邮宣传管理',
    icon: <EmailIcon />
  },
  {
    segment: 'plusOneCampaign',
    title: '1+1活动管理',
    icon: <PlusOneIcon />
  },
  {
    kind: 'divider'
  },
  {
    segment: 'logout',
    title: '退出平台',
    icon: <LogoutIcon /> 
  }
];
