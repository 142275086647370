import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  ChangeEvent,
  CSSProperties,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuth } from "../../../Context/AuthContext";
import { useLocation, useNavigate } from "react-router";
import { useNotifications } from "@toolpad/core";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from "dayjs";

import CancelIcon from "@mui/icons-material/Cancel";

type langResObjectType = {
  lang:string,
  title:string,
  summary?:string
}

const langs:langSetType[] = [
  { name: '英文', code:'en'},
  { name: '日文', code:'jp' },
  { name: '德文', code:'de'},
  { name: '法文', code:'fr'},
  { name: '意大利文', code:'it'},
  { name: '韩文', code:'ko'},
  { name: '西班牙文', code:'es'},
]

const rowFlexStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  gap: 1,
};

const colFlexStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: 1,
};

export default function PlusOneEditorPage() {
  const location = useLocation();
  const { token } = useAuth();
  const navigate = useNavigate();
  const notification = useNotifications();

  const [logic, setLogic] = useState<'and'|'or'>("or");
  const [conditionFaces, setConditionFaces] = useState<string[]>([]);
  const [resultFace, setResultFace] = useState<string>("");
  const [watchfaceList, setWatchfaceList] = useState<ItemType[]>([]);
  const [title, setTitle] = useState<string>("");
  const [summary, setSummary] = useState<string>('');
  const [startdate, setStartdate] = useState<Dayjs>(dayjs());
  const [enddate, setEnddate] = useState<Dayjs>(dayjs().add(7, 'days'));
  const [supportlang, setSupportlang] = useState<string[]>([]);
  const [langResObject, setLangResObject] = useState<langResObjectType[]>([])
  const [editorAction, setEditorAction] = useState<string>(location.state ? location.state.action ?? '' : '')
  const [campaignUid, setCampaignUid] = useState<string>('')


  const saveCampaign = async () => {
    if (conditionFaces.findIndex((elm) => elm === resultFace) >= 0) {
      notification.show("重复选择了购买的与赠送的表盘，请重新选择赠送表盘", {
        severity: "warning",
        autoHideDuration: 3000,
      });
      setResultFace("");
      return;
    }
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify({
          uid: campaignUid,
          title: title,
          logical_option: logic,
          oneface: conditionFaces,
          plusface: resultFace,
          starttime: startdate.unix(),
          endtime: enddate.unix(),
          summary: summary,
          langres: langResObject
        })
      }
      const response = await fetch(editorAction === 'EDIT' ? '/api/updPlusOneCampaign': '/api/addPlusOneCampaign', options)
      if (response.status === 200) {
        navigate('/plusOneCampaign')
      }
      if (response.status === 401 || response.status === 403) {
        navigate('/login', { state: { uri: location.pathname }})
      }
    } catch (err) {
      console.log(err)
    }
  };

  const changeLogic = (e: ChangeEvent<HTMLInputElement>) => {
    setLogic( e.target.value as 'and'|'or');
  };

  const clearFaces = () => {
    setConditionFaces([]);
  };

  const selectFaces = (event: SelectChangeEvent<typeof conditionFaces>) => {
    const {
      target: { value },
    } = event;
    setConditionFaces(typeof value === "string" ? value.split(",") : value);
  };

  const clearResultFace = () => {
    setResultFace("");
  };

  const selectResultFace = (event: SelectChangeEvent) => {
    setResultFace(event.target.value as string);
  };

  const langCheck = (e:ChangeEvent<HTMLInputElement>) => {
    if(supportlang.indexOf(e.target.value) < 0) {
      setSupportlang(prev => [...prev, e.target.value])
      setLangResObject(prev => [...prev, { lang:e.target.value, title:'', summary:''}])
    } else {
      console.log('remove', e.target.value)
      setSupportlang(prev => [ ...prev.slice(0, prev.indexOf(e.target.value)), ...prev.slice(prev.indexOf(e.target.value)+1)])
      setLangResObject(prev => [...prev.slice(0, prev.indexOf(prev.find(i => i.lang === e.target.value) as langResObjectType)), 
        ...prev.slice(prev.indexOf(prev.find(i => i.lang === e.target.value) as langResObjectType)+1)
      ])
    }
  }
  
  function handleLangChangeDesc(e:ChangeEvent<HTMLInputElement>, obj:langResObjectType) {
    setLangResObject(prev => [...prev.slice(0,prev.indexOf(obj)), { ...obj, summary:e.target.value}, ...prev.slice(prev.indexOf(obj)+1)])
  }

  function handleLangChangeTitle(e:ChangeEvent<HTMLInputElement>, obj:langResObjectType) {
    setLangResObject(prev => [...prev.slice(0,prev.indexOf(obj)), { ...obj, title:e.target.value}, ...prev.slice(prev.indexOf(obj)+1)])  
  }

  const getWatchFaces = useCallback(async () => {
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          disablePagi: true,
        }),
      };
      const response = await fetch("/api/watchlist", options);
      if (response.status === 200) {
        const data = await response.json();
        setWatchfaceList(data);
      }
      if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }
      if (response.status === 400) {
        const detail = await response.text();
        notification.show(detail, {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, []);


  const campaignData = useMemo( async () => {
    if (!location.state) return
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify({ id: location.state.id ?? undefined })
      }
      const response = await fetch('/api/getPlusOneCampaign', options)
      if (response.status === 200) {
        const data = await response.json()
        setCampaignUid(data.uid)
        setLogic(data.logical_option)
        setConditionFaces(JSON.parse(data.oneface))
        setResultFace(data.plusface)
        setTitle(data.title)
        setSummary(data.summary)
        setStartdate(dayjs(data.starttime))
        setEnddate(dayjs(data.endtime))
        setSupportlang(data.supportlang)
        if (data.supportlang) {
          if (data.supportlang.length !== supportlang.length) {
            data.supportlang.map( (l:string) => {
              const jsonobj = data[l]
              let objdetail:langResObjectType = {} as langResObjectType
              if(jsonobj) {
                objdetail = JSON.parse(jsonobj.resobj) as langResObjectType
                objdetail.lang = l
                setLangResObject(prev => [...prev, objdetail])
              } 
            })  
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  }, [editorAction])


  useEffect(() => {
    getWatchFaces();
  }, [getWatchFaces]);

  const langform = (lang:string) => {
    const jsonobj = langResObject.find((elm) => (elm.lang === lang))
    const langname = langs.find((elm) => elm.code = lang)
    return (
    <Box sx={{ml:2, display:'flex', flexDirection:'column',gap:1}}>
      <Divider textAlign="left">{langname?.name}</Divider>
      <Box sx={{ display:'flex', flexDirection:'row', gap:1}}>
        <FormControl sx={{ width: 1/2 }}>
          <InputLabel aria-label="textfield_item_n">{langname?.name}名称</InputLabel>
          <OutlinedInput
            aria-label="textfield_item_n"
            label={langname?.name+'标题'}
            value={jsonobj?.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleLangChangeTitle(e, jsonobj as langResObjectType)
            }
          />
          <FormHelperText id="textfield_item_n">
            最少5个字
          </FormHelperText>
        </FormControl>
        <FormControl sx={{ width: 1/2 }}>
          <InputLabel aria-label="textfield_item_n">{langname?.name}描述</InputLabel>
          <OutlinedInput
            aria-label="textfield_item_n"
            label={langname?.name+"描述"}
            multiline={true}
            rows={4}
            value={jsonobj?.summary ?? ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleLangChangeDesc(e, jsonobj as langResObjectType)
            }
          />
        </FormControl>
      </Box>
    </Box>
  )}

  const StickyButtonsComponent = (
    <Box
      sx={(theme) => ({
        position: "sticky",
        width: 1,
        ...rowFlexStyle,
        backgroundColor: theme.palette.mode === "light" ? "white" : "#121212",
        bottom: 0,
        justifyContent: "flex-end",
        pb: "16px",
      })}
    >
      <Button
        variant="contained"
        sx={{ mr: 2 }}
        onClick={saveCampaign}
        disabled={
          resultFace.length === 0 ||
          title.length === 0 ||
          conditionFaces.length === 0
        }
      >
        { location.state ? (location.state.action === 'EDIT') ? '更新': '保存': '保存'}
      </Button>
    </Box>
  );

  const LogicalComponent = (
    <Fragment>
      <Typography sx={{ fontSize: 12, textDecorationLine: "underline" }}>
        +1限制条件:
      </Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          row
          value={logic}
          onChange={changeLogic}
        >
          <FormControlLabel
            value="and"
            control={<Radio />}
            label="所有选择的 (AND)"
          />
          <FormControlLabel
            value="or"
            control={<Radio />}
            label="其中选择的 (OR)"
          />
        </RadioGroup>
      </FormControl>
    </Fragment>
  );

  const TitleComponent = (
    <Box sx={{...colFlexStyle}}>
      <FormControl size="small" fullWidth sx={{ pb: 1 }}>
        <InputLabel aria-label="campaign_name">活动标题</InputLabel>
        <OutlinedInput
          sx={{ width: 1 / 2 }}
          id="campaign_name"
          size="small"
          label="活动标题"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          endAdornment={
            <InputAdornment position="end">
              {title.length > 0 && (
                <IconButton
                  onClick={() => {
                    setTitle("");
                  }}
                >
                  <CancelIcon sx={{ fontSize: 16 }} />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl size="small" fullWidth sx={{ pb: 1 }}>
        <InputLabel aria-label="campaign_summary">活动描述</InputLabel>
        <OutlinedInput
          id="campaign_summary"
          size="small"
          label="活动描述"
          value={summary}
          multiline
          rows={5}
          onChange={(e) => {
            setSummary(e.target.value);
          }}
          endAdornment={
            <InputAdornment position="end">
              {summary.length > 0 && (
                <IconButton
                  onClick={() => {
                    setSummary("");
                  }}
                >
                  <CancelIcon sx={{ fontSize: 16 }} />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );

  const MultiFaceSelectorComponent = (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pb: 1 }}>
      <Typography sx={{ fontSize: 12, textDecorationLine: "underline" }}>
        选择需要购买的表盘（前置条件）:
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
        }}
      >
        <FormControl size="small" fullWidth>
          <InputLabel aria-label="tag">需购买的表盘</InputLabel>
          <Select
            id="tag"
            value={conditionFaces}
            onChange={selectFaces}
            autoWidth
            multiple
            input={
              <OutlinedInput
                label="需购买的表盘"
                endAdornment={
                  <InputAdornment position="end" sx={{ mr: 3 }}>
                    {conditionFaces.length > 0 && (
                      <IconButton onClick={() => clearFaces()}>
                        <CancelIcon sx={{ fontSize: 16 }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => {
                  let displayVal = watchfaceList.map((w) => {
                    if (w.uid === value) return w.name;
                  });
                  return <Chip key={value} label={displayVal} size="small" />;
                })}
              </Box>
            )}
          >
            {watchfaceList &&
              watchfaceList.map((t, i) => (
                <MenuItem key={i} value={t.uid}>
                  {t.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );

  const PlusOneFaceComponent = (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pb: 2 }}>
      <Typography sx={{ fontSize: 12, textDecorationLine: "underline" }}>
        选择赠送的表盘（前置条件）:
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
        }}
      >
        <FormControl size="small" fullWidth>
          <InputLabel aria-label="plusone">赠送表盘</InputLabel>
          <Select
            id="plusone"
            value={resultFace ?? ''}
            onChange={selectResultFace}
            autoWidth
            input={
              <OutlinedInput
                label="赠送表盘"
                endAdornment={
                  <InputAdornment position="end" sx={{ mr: 3 }}>
                    {resultFace.length > 0 && (
                      <IconButton onClick={() => clearResultFace()}>
                        <CancelIcon sx={{ fontSize: 16 }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {watchfaceList.map((w) => {
                  if (w.uid === selected)
                    return <Chip key={selected} label={w.name} size="small" />;
                })}
              </Box>
            )}
          >
            {watchfaceList &&
              watchfaceList.map((t, i) => (
                <MenuItem key={i} value={t.uid}>
                  {t.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );

  const DateRangeComponent = (
    <Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{display:'flex', flexDirection:'row', gap: 1, pb:2}}>
        <DatePicker sx={{width:1/2}} label='开始日期' value={startdate} onChange={(newValue) => setStartdate(newValue as Dayjs)}/>
        <DatePicker sx={{width:1/2}} label='结束日期' value={enddate} onChange={(newValue) => setEnddate(newValue as Dayjs)}/>
        </Box>
      </LocalizationProvider>
    </Fragment>
  )

  const LangChooseComponent = (
    <Fragment>
      <Typography
        sx={{
          fontSize: 12,
          textDecorationLine: "underline",
          ml: 2,
          mb: 1,
        }}
      >
        增加语言支持
      </Typography>
      <FormControl>
        <FormGroup
          sx={{ display: "flex", flexDirection: "row", gap: 2, ml: 2 }}
        >
          { langs.map( (item,idx) => (
            <FormControlLabel key={idx} value={item.code} control={
              <Checkbox size="small" onChange={langCheck} checked={supportlang.indexOf(item.code)>=0} />
              } label={item.name}
            />
          ))}
        </FormGroup>
      </FormControl>
      {
          supportlang.map((item,idx) => (
            <Box key={idx}>
              {langform(item)}
            </Box>
          ))
        }

    </Fragment>
  )

  return (
    <Box sx={{ position: "relative", height: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          minHeight: "calc(100% - 64px)",
        }}
      >
        <Typography variant="h4" sx={{ pb: 2 }}>
        { location.state ? (location.state.action === 'EDIT') ? '编辑': '创建': '创建'}1+1活动
        </Typography>
        {TitleComponent}
        {LogicalComponent}
        { MultiFaceSelectorComponent }
        { PlusOneFaceComponent }
        { DateRangeComponent }
        { LangChooseComponent }
      </Box>
      {StickyButtonsComponent}
    </Box>
  );
}
