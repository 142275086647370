import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  ChangeEvent,
  CSSProperties,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuth } from "../../Context/AuthContext";
import { useNavigate } from "react-router";
import { useDialogs, useNotifications } from "@toolpad/core";
import { ConfirmProceed } from "../../Components/Dialogs";
import LoadingBackDrop from "../../Components/LoadingBackDrop";

import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FolderOffIcon from "@mui/icons-material/FolderOff";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";

const TableCellCust = styled(TableCell)(() => ({
  padding: "4px",
  minWidth: "80px",
}));

const monoSpace: CSSProperties = {
  fontFamily: "monospace",
  whiteSpace: "nowrap",
  textWrap: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const rowFlex: CSSProperties = {
  display:'flex',
  flexDirection:'row',
  gap: 1,
  width: 1,
  alignItems:'center'
}

const lableTypographyStyle: CSSProperties = {
  fontSize: '12px',
  textDecorationLine: "underline" 
}

const NumberInput = styled(OutlinedInput)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

interface itemRefType {
  uid: string;
  name: string;
}

interface tagsType {
  tag: string;
}

const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL

export default function ManageProductPage() {
  // trigger render state
  const [refresh, setRefresh] = useState<boolean>(true);
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ exeDownload, setExeDownload ] = useState<boolean>(false)

  // search parameters
  const [searchString, setSearchString] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationType>({
    currentPage: 0,
    pageSize: 10,
    totalRows: 0,
    disablePagi: false,
  } as PaginationType);
  const [isSomething, setIsSomething] = useState<boolean[]>(
    new Array(3).fill(false)
  );
  const [selectedTag, setSelectedTag] = useState<string[]>([]);

  // display parameters
  const [list, setList] = useState<ProductType[]>([]);
  const [itemRef, setItemRef] = useState<itemRefType[]>([]);
  const [tags, setTags] = useState<tagsType[]>([]);

  // new product parameters
  const [numCodes, setNumCodes] = useState<number|''>(0);
  const [tagString, setTagString] = useState<string>("");
  const [itemUid, setItemUid] = useState<itemRefType>({} as itemRefType);
  const [ itemUidString, setItemUidString ] = useState<string>('')

  const { token } = useAuth();
  const navigate = useNavigate();
  const notification = useNotifications()
  const dialog = useDialogs()
  const matches = useMediaQuery('(min-width:900px)')

  const clearSearch = () => {
    setSearchString("");
    setPagination((prev) => ({ ...prev, currentPage: 0 }));
    setRefresh(true);
  };

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setPagination((prev) => ({ ...prev, currentPage: 0 }));
      setRefresh(true);
    }
  };

  const onSearch = () => {
    setPagination((prev) => ({ ...prev, currentPage: 0 }));
    setRefresh(true);
  };

  const onPageChange = (e: any, page: number) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
    setRefresh(true);
  };

  const onChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
    setPagination((prev) => ({
      ...prev,
      pageSize: parseInt(e.target.value, 10),
      currentPage: 0,
    }));
    setRefresh(true);
  };

  const handleCreateProducts = () => {
    if (!numCodes || (numCodes as number) <= 0) {
      notification.show('密卡数量最少要1张', {
        severity: 'warning',
        autoHideDuration: 3000
      })
      return;
    }

    let uidObject = itemUid
    if (itemUidString.length > 0 && Object.keys(uidObject).length === 0) {
      uidObject = { uid:itemUidString, name:itemUidString}
      setItemUid(uidObject)
    }
    if (Object.keys(uidObject).length === 0) {
      notification.show('必须选择对应表盘', {
        severity: 'warning',
        autoHideDuration: 3000
      })
      return;
    };
    ( async () => {
      await dialog.open(ConfirmProceed, {
        text: '生成密卡'+numCodes+'张',
        confirmText: '继续生成',
        important: '表盘编码:'+uidObject.uid+' 分类群组:'+(tagString?tagString:'没有'),
        highlight: tagString ? '带分类群组默认密卡已发出':'没有分类群组默认未发出',
        confirmCheck: true
      }).then((val) => {
        if (val) {
          setLoading(true)
          createCards()
        }
      })
    })()
  }

  const createCards = async () => {
    try {
      const options = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          num: numCodes,
          tag: tagString,
          itemuid: itemUid.uid
        })
      }
      const response = await fetch('/api/createProductCards', options)
      if (response.status === 200) {
        // const data = await response.text()
        setSelectedTag([]);
        setRefresh(true)
      }
      setLoading(false)
      if (response.status === 401 || response.status === 403) {
        navigate('/login')
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getList = useCallback(async () => {
    if (!refresh) return;
    try {
      const posIsXXX:boolean = isSomething[0] ? !(isSomething[1] && isSomething[2]) : isSomething[1] ? !(isSomething[0] && isSomething[2]) : isSomething[2] ?  !(isSomething[0] && isSomething[1]): false
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          ...pagination,
          search: searchString,
          tags: selectedTag,
          isXXX: posIsXXX ? isSomething : []
        }),
      };
      const response = await fetch("/api/productList", options);
      if (response.status === 200) {
        const data = await response.json();
        setList(data.list);
        setPagination((prev) => ({ ...prev, totalRows: data.total }));
        setItemRef(data.itemref);
        setTags(data.tags);
      }
      setRefresh(false);
      if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, token]);

  const handleSelector = (event: SelectChangeEvent<typeof selectedTag>) => {
    const {
      target: { value },
    } = event;
    setSelectedTag(typeof value === "string" ? value.split(",") : value);
    setRefresh(true);
  };

  const clearTags = () => {
    setSelectedTag([]);
    setRefresh(true);
  };

  const toggleSomething = (pos:number) => {
    setPagination((prev) => ({ ...prev, currentPage: 0 }));
    setIsSomething(prev => {
      return prev.map((p,i) => {
        if (pos === i)
          return !p
        return p
      })
    })
    setRefresh(true)
  }

  const controlledTypingItemUid = (e:ChangeEvent<HTMLInputElement>) => {
    setItemUid({} as itemRefType)
    setItemUidString(e.target.value)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const generateExcel = useMemo( async () => {
    if (!exeDownload) return
    try {
      const posIsXXX:boolean = isSomething[0] ? !(isSomething[1] && isSomething[2]) : isSomething[1] ? !(isSomething[0] && isSomething[2]) : isSomething[2] ?  !(isSomething[0] && isSomething[1]): false
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          ...pagination,
          search: searchString,
          tags: selectedTag,
          isXXX: posIsXXX ? isSomething : []
        }),
      };
      const response = await fetch("/api/downloadExcel", options);
      if (response.ok) {
        const content = await response.json()
        const fileres = await fetch(`${PUBLIC_URL}download/report/${content.file}`)
        if (fileres.ok) {
          const blob = await fileres.blob()
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.download = content.file
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }      
      }
      if (response.status === 401 || response.status === 403) {
        navigate('/login')
      }
      if (response.status === 400) {
        const detail = await response.text()
        notification.show(detail,{
          severity:'error',
          autoHideDuration: 3000
        })
      }
      setExeDownload(false)
    } catch (err) {
      console.log(err)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exeDownload])

  const handleDeleteProduct = async (uid:string) => {
    await dialog.open(ConfirmProceed, {
      text: "确认删除密卡记录",
      confirmText: "删除",
      important:
        "删除密卡记录会影响已验证的UUID不能继续使用该密卡号码对应的表盘",
      highlight: "确认需要执行删除密卡",
      confirmCheck: true,
    })
    .then((val) => {
      if(val) {
        setLoading(true)
        deleteProduct(uid)
      }
    })
  }

  const deleteProduct = async (uid:string) => {
    const options = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify({
        uid: uid
      })
    };
    
    const response = await fetch('/api/remove/gm_record', options)
    if (response.status === 200) {
      setLoading(false)
      setRefresh(true)
    }
    if (response.status === 403) {
      navigate('/login')
    }
  }

  const disCloseProduct = async (item_uid:string, prod_uid:string) => {
    await dialog.open(ConfirmProceed, {
      text: "确认发出该密卡号码",
      confirmText: "发出",
      important:
        "操作后该密卡状态为可被激活状态，注意不要重复发出密卡号码",
    })
    .then((val) => {
      if (val) {
        setLoading(true);
        ( async () => {
          try {
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
              body: JSON.stringify({
                item_uid: item_uid,
                prod_uid: prod_uid,
              }),
            };
            const res = await fetch("/api/update_card_state", options);
            if (res.status === 200) {
              notification.show("成功发出", {
                severity: "success",
                autoHideDuration: 3000,
              });
              setRefresh(true)
            }
            if (res.status === 403) {
              navigate("/login");
            }
            if (res.status === 400) {
              const detail = await res.json();
              notification.show(detail.detail.message, {
                severity: "error",
                autoHideDuration: 3000,
              });
            }
          } catch (err) {
            console.log(err);
          }
          setLoading(false)
        })()
      }
    })
  }

  useEffect(() => {
    getList();
    return () => setRefresh(false);
  }, [getList]);

  const keySearchComponnet = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        mb: 1,
      }}
    >
      <TextField
        label="搜索"
        size="small"
        fullWidth
        value={searchString}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchString.length > 0 && (
                  <IconButton onClick={() => clearSearch()}>
                    <CancelIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleSearchInput(e);
        }}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          handleKeyDown(e);
        }}
      />
      <Button
        sx={{ minWidth: 150, p: 1, mt: 1, mb: 1 }}
        variant="contained"
        size="small"
        onClick={onSearch}
      >
        搜索
      </Button>
    </Box>
  );

  const CreatorComponent = (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 1 }}>
      <Typography sx={{ fontSize: 12, textDecorationLine: "underline" }}>
        新增平台密卡:
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          pb: 1,
        }}
      >
        <FormControl size="small" sx={{minWidth:100}}>
          <InputLabel htmlFor="code_num">密卡数量</InputLabel>
          <NumberInput
            id="code_num"
            size="small"
            label="验证码数量"
            type="number"
            value={numCodes}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setNumCodes(parseInt(e.target.value, 10) || '');
            }}
          />
        </FormControl>
        <Autocomplete
          size="small"
          freeSolo
          sx={{ minWidth:250}}
          options={tags.map(t => (t.tag))}
          onChange={(event: any, newValue: string | null) => {
            setTagString(newValue as string)
          }}
          id="tagstring"
          renderInput={(params) => <TextField {...params} label="群组标签" onChange={(e)=>setTagString(e.target.value)} />}
        />
        <Autocomplete
          size="small"
          freeSolo
          options={itemRef}
          getOptionLabel={(option) => (option as itemRefType).name}
          onChange={(event: any, newValue: itemRefType | string | null) => {
            if (newValue && typeof newValue === "object") {
              setItemUid(newValue as itemRefType);
            } else {
              setItemUid({} as itemRefType)
              setItemUidString('')
            }
          }}
          id="item_uid"
          sx={{ minWidth:250}}
          renderInput={(params) => <TextField {...params} label="表盘编码" onChange={controlledTypingItemUid} />}
        />
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            handleCreateProducts();
          }}
        >
          新增
        </Button>
      </Box>
    </Box>
  );

  const CreatorComponentMobile = (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography sx={{ fontSize: 12, textDecorationLine: "underline" }}>
          新增平台密卡:
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 1 }}>
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <FormControl size="small" fullWidth>
            <InputLabel htmlFor="code_num">密卡数量</InputLabel>
            <NumberInput
              id="code_num"
              size="small"
              label="验证码数量"
              type="number"
              value={numCodes}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setNumCodes(parseInt(e.target.value, 10) || '');
              }}
            />
          </FormControl>
          <Autocomplete
            size="small"
            freeSolo
            sx={{ minWidth:250}}
            options={tags.map(t => (t.tag))}
            onChange={(event: any, newValue: string | null) => {
              setTagString(newValue as string)
            }}
            id="tagstring"
            renderInput={(params) => <TextField {...params} label="群组标签" onChange={(e)=>setTagString(e.target.value)} />}
          />
          <Autocomplete
            size="small"
            freeSolo
            options={itemRef}
            getOptionLabel={(option) => (option as itemRefType).name}
            onChange={(event: any, newValue: itemRefType | string | null) => {
              if (newValue && typeof newValue === "object") {
                setItemUid(newValue as itemRefType);
              } else {
                setItemUid({} as itemRefType)
                setItemUidString('')
              }
            }}
            id="item_uid"
            sx={{ minWidth:250}}
            renderInput={(params) => <TextField {...params} label="表盘编码" onChange={controlledTypingItemUid} />}
          />
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              handleCreateProducts();
            }}
          >
            新增
          </Button>
        </Box>
      </Box>
      </AccordionDetails>
    </Accordion>
  )

  const TagSelectorComponent = (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pb: 1 }}>
      <Typography sx={{ fontSize: 12, textDecorationLine: "underline" }}>
        只显示标签:
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
        }}
      >
        <FormControl size="small" fullWidth>
          <InputLabel htmlFor="tag">群组标签</InputLabel>
          <Select
            labelId="tag"
            id="tag"
            value={selectedTag}
            onChange={handleSelector}
            autoWidth
            multiple
            input={
              <OutlinedInput
                label="群组标签"
                endAdornment={
                  <InputAdornment position="end" sx={{ mr: 3 }}>
                    {selectedTag.length > 0 && (
                      <IconButton onClick={() => clearTags()}>
                        <CancelIcon sx={{ fontSize: 16 }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} size="small" />
                ))}
              </Box>
            )}
          >
            {tags &&
              tags.map((t, i) => (
                <MenuItem key={i} value={t.tag}>
                  {t.tag}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText id="tag">
            不选择任何群组=展示所有群组标签
          </FormHelperText>
        </FormControl>
      </Box>
    </Box>
  );

  const EnablerComponent = (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography sx={{ fontSize: 12, textDecorationLine: "underline" }}>
        密卡使用状态显示筛选:
      </Typography>
      <FormControl size="small" fullWidth>
        <FormGroup
          aria-label="status"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 4,
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "rgba(0,0,0,0.14)",
            borderRadius: "4px",
            px: 1,
          }}
        >
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="未使用的"
            onChange={()=> toggleSomething(0)}
          />
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="已发出的"
            onChange={()=> toggleSomething(1)}
          />
          <FormControlLabel
            control={<Checkbox size="small" />}
            label="已验证的"
            onChange={()=> toggleSomething(2)}
          />
        </FormGroup>
        <FormHelperText id="status">
          单选=只显示该状态为True，双选=状态1或状态2为True，全选=1，,2或3为True（未选择的状态不参与搜索项）
        </FormHelperText>
      </FormControl>
    </Box>
  );

  const EnablerComponentMobile = (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography sx={{ fontSize: 12, textDecorationLine: "underline" }}>
          密卡使用状态显示筛选:
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl size="small" fullWidth>
          <FormGroup
            aria-label="status"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 4,
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "rgba(0,0,0,0.14)",
              borderRadius: "4px",
              px: 1,
            }}
          >
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="未使用的"
              onChange={()=> toggleSomething(0)}
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="已发出的"
              onChange={()=> toggleSomething(1)}
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="已验证的"
              onChange={()=> toggleSomething(2)}
            />
          </FormGroup>
          <FormHelperText id="status">
            单选=只显示该状态为True，双选=状态1或状态2为True，全选=1，,2或3为True（未选择的状态不参与搜索项）
          </FormHelperText>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );

  const TrueFalseComponent = (state: boolean) => {
    return state ? (
      <CheckCircleOutlineOutlinedIcon color="primary" />
    ) : (
      <DisabledByDefaultOutlinedIcon color="secondary" />
    );
  };

  const ListTable = (
    <Paper
      sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', gap: 2}}>
        <Typography sx={{ fontSize: 12 }}>
          (可下载目前搜索列表) 
        </Typography> 
        <Button size='small' variant='contained' onClick={()=>{ setExeDownload(true) }}>下载密卡Excel</Button>
      </Box>
      <TableContainer component={Paper} sx={{ p: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCellCust rowSpan={2}>密卡号码</TableCellCust>
              <TableCellCust rowSpan={2}>产品编码</TableCellCust>
              <TableCellCust rowSpan={2}>产品描述</TableCellCust>
              <TableCellCust rowSpan={2}>群组标签</TableCellCust>
              <TableCellCust rowSpan={2}>生成时间</TableCellCust>
              <TableCellCust colSpan={3} align="center">密卡状态</TableCellCust>
              <TableCellCust rowSpan={2}>发出时间</TableCellCust>
              <TableCellCust rowSpan={2}>验证时间</TableCellCust>
              <TableCellCust rowSpan={2}>Action</TableCellCust>
            </TableRow>
            <TableRow>
              <TableCellCust align="center" sx={{ maxWidht: "2ch", minWidth: 0 }} >未</TableCellCust>
              <TableCellCust align="center" sx={{ maxWidht: "2ch", minWidth: 0 }} >出</TableCellCust>
              <TableCellCust align="center" sx={{ maxWidht: "2ch", minWidth: 0 }} >验</TableCellCust>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item, idx) => {
              // const date = new Date(item.createtime)
              return (
                <TableRow key={idx}>
                  <TableCellCust>
                    <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                      <Typography sx={monoSpace}>{item.uid}</Typography>
                      <Button size='small' color='secondary' variant='outlined' sx={{p:0}} onClick={async ()=>await navigator.clipboard.writeText(item.uid)}>复制</Button> 
                    </Box>
                  </TableCellCust>
                  <TableCellCust sx={monoSpace}>{item.ref_item}</TableCellCust>
                  <TableCellCust sx={{ maxWidth: 120 }}>{item.name}</TableCellCust>
                  <TableCellCust>{item.tag}</TableCellCust>
                  <TableCellCust>{item.createtime}</TableCellCust>
                  <TableCellCust
                    align="center"
                    sx={{ maxWidht: "2ch", minWidth: 0 }}
                  >
                    {TrueFalseComponent(item.is_unuse)}
                  </TableCellCust>
                  <TableCellCust
                    align="center"
                    sx={{ maxWidht: "2ch", minWidth: 0 }}
                  >
                    {TrueFalseComponent(item.is_disclosed)}
                  </TableCellCust>
                  <TableCellCust
                    align="center"
                    sx={{ maxWidht: "2ch", minWidth: 0 }}
                  >
                    {TrueFalseComponent(item.is_verified)}
                  </TableCellCust>
                  <TableCellCust>{item.disclosed_on}</TableCellCust>
                  <TableCellCust>{item.verified_on}</TableCellCust>
                  <TableCellCust>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        height: 24,
                      }}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteProduct(item.uid)}
                      >
                        删除密卡
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        disabled={!item.is_unuse}
                        onClick={() => disCloseProduct(item.ref_item, item.uid)}
                      >
                        发密卡
                      </Button>
                    </Box>
                  </TableCellCust>
                </TableRow>
              );
            })}
            {list.length === 0 && (
              <TableRow>
                <TableCellCust colSpan={11} align="center">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      justifyContent: "center",
                      color: "GrayText",
                      p: 3,
                    }}
                  >
                    <FolderOffIcon />
                    <Typography variant="inherit">未有数据</Typography>
                  </Box>
                </TableCellCust>
              </TableRow>
            )}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          labelRowsPerPage="每页显示"
          component="div"
          count={pagination.totalRows as number}
          rowsPerPage={pagination.pageSize}
          page={pagination.currentPage}
          colSpan={11}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );

  const ListTableMobile = (
    <Paper
      sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column", gap: 2 }}
    >
        {list.map((item, idx) => (
          <Box key={idx} sx={{display:'flex', flexDirection:'column', gap:2, borderBottom:'solid', pb:1}}>
            <Box sx={{...rowFlex}}>
              <Typography sx={lableTypographyStyle}>密卡号码</Typography>
              <Typography sx={monoSpace}>{item.uid}</Typography>
              <Button size='small' color='secondary' variant='outlined' sx={{p:0}} onClick={async ()=>await navigator.clipboard.writeText(item.uid)}>复制</Button> 
            </Box>
            <Box sx={{...rowFlex}}>
              <Typography sx={lableTypographyStyle}>产品编码</Typography>
              <Typography sx={monoSpace}>{item.ref_item}</Typography>
            </Box>
            <Box sx={{...rowFlex}}>
              <Typography sx={lableTypographyStyle}>产品描述</Typography>
              <Typography sx={monoSpace}>{item.name}</Typography>
            </Box>
            <Box sx={{...rowFlex }}>
              <Typography sx={lableTypographyStyle}>密卡状态</Typography>
              <Typography sx={lableTypographyStyle}>未用:</Typography>
              {TrueFalseComponent(item.is_unuse)}
              <Divider orientation="vertical" flexItem />
              <Typography sx={lableTypographyStyle}>发出:</Typography>
              {TrueFalseComponent(item.is_disclosed)}
              <Divider orientation="vertical" flexItem />
              <Typography sx={lableTypographyStyle}>验证:</Typography>
              {TrueFalseComponent(item.is_verified)}
            </Box>
            <Box sx={{...rowFlex}}>
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => handleDeleteProduct(item.uid)}
              >
                删除密卡
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                disabled={!item.is_unuse}
                onClick={() => disCloseProduct(item.ref_item, item.uid)}
              >
                发密卡
              </Button>
            </Box>
          </Box>
        ))}
    </Paper>
  )

  return (
    <>
    { matches ? (
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <Typography variant="h4" sx={{ pb: 2 }}>
          密卡管理列表 
        </Typography>
        {CreatorComponent}
        {keySearchComponnet}
        {TagSelectorComponent}
        {EnablerComponent}
        {ListTable}
        <LoadingBackDrop open={loading || exeDownload} handleClose={() => setLoading(false)} />
      </Box>

    ): (
      <Box sx={{ display: "flex", flexDirection: "column", gap:1, p:1 }}>
        <Typography variant="h5" sx={{ pb: 2 }}>
          密卡管理列表 (Mobile)
        </Typography>
        {CreatorComponentMobile}
        {EnablerComponentMobile}
        {keySearchComponnet}
        {ListTableMobile}
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          labelRowsPerPage="每页显示"
          component="div"
          count={pagination.totalRows as number}
          rowsPerPage={pagination.pageSize}
          page={pagination.currentPage}
          colSpan={11}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    )}
    </>
  );
}
